<template>
  <div class="tab on">配信予定・結果<span class="me-2"/><font-awesome-icon icon="fas fa-question-circle" class="blue"  data-bs-toggle="modal" data-bs-target="#main_help"/></div>
  <router-link to="/edit" v-slot="{ navigate }" custom>
    <div class="tab" @click="navigate">原稿設定</div>
  </router-link>
  <div>
    <div class="list">
      <div>
        <div class="search">
          <span>期間</span>
          <RectusPeriodpicker v-model="delivery_date_for_search"/>
          <span>原稿名</span>
          <input type="text" v-model="script_name">
          <button class="btn btn-outline-primary" @click="get_schedule_and_result(true, false, false)">検索</button>
        </div>
      </div>
      <div class="m-2" id="infinite_scroll" ref="scroll_area">
        <table class="table table-striped">
          <colgroup>
            <col style="min-width: 80px;width: 10%;">
            <col style="width: 30px;">
            <col style="min-width: 250px;width: 40%;">
            <col style="width: 9%;">
            <col style="width: 8%;">
            <col style="width: 10%;">
            <col style="width: 10%;">
            <col style="width: 10%;">
            <col style="width: 10%;">
          </colgroup>
          <thead>
            <tr>
              <th rowspan="2">配信<br>(予定)日時</th>
              <th rowspan="2" colspan="2">配信No.<br>原稿名<br>配信リスト</th>
              <th rowspan="2">基準</th>
              <th rowspan="2">配信<br>(予定)数</th>
              <th>配信結果</th>
              <th>効果測定</th>
              <th>アンケート</th>
              <th>確実な閲覧</th>
            </tr>
            <tr>
              <th>到達<br>エラー<br>停止</th>
              <th>クリック<br>追跡</th>
              <th>閲覧<br>回答</th>
              <th>文中クリック<br>直接HP<br>合計<br>動画視聴</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="s in schedules" :key="s.inhaisouno" :class="s.bsent != 't' ? 'future' : ''">
              <template v-if="s.inhaisouno == '-1'">
              <td colspan="9">読み込み中...</td>
              </template>
              <template v-else>
              <td>{{ s.tihaisou && s.tihaisou.substr(0, s.tihaisou.length - 3) }}</td>
              <template v-if="s.bsent != 't'">
                <td><div><a :href="get_edit_page_url(s)" target="_blank"><font-awesome-icon icon="fa-solid fa-edit" class="fa-lg"/></a></div><div class="yubi"><font-awesome-icon icon="fa-solid fa-calendar-alt" @click="change_schedule(s)" class="fa-lg" data-bs-toggle="modal" data-bs-target="#reserve_modal"/></div><div class="yubi"><font-awesome-icon icon="fa-solid fa-trash" class="fa-lg" @click="delete_schedule(s.inhaisouno)"/></div></td>
                <td><div>No.{{ s.inhaisouno }}</div><a data-bs-toggle="modal" href="#preview_modal" @click="open_preview_modal(s)">{{ s.stsubject }}</a><div>{{ s.listfullname }}</div></td>
                <td>{{ s.options_criteria }}</td>
                <td>
                  <template v-if="!s.num"> {{ s.num }}</template>
                  <template v-else><span name="deliveryschedulenum" class="download-link" @click="download(s.inhaisouno)"> {{ s.num }}</span></template>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </template>
              <template v-else>
                <td><div><a :href="get_edit_page_url(s)" target="_blank"><font-awesome-icon icon="fa-solid fa-edit" class="fa-lg"/></a></div><div class="yubi"><font-awesome-icon icon="fa-solid fa-calendar-alt" @click="add_schedule(s)" class="fa-lg" data-bs-toggle="modal" data-bs-target="#reserve_modal"/></div></td>
                <td><div>No.{{ s.inhaisouno }}</div><a data-bs-toggle="modal" href="#preview_modal" @click="open_preview_modal(s)">{{ s.stsubject }}</a><div>{{ s.listfullname }}</div></td>
                <td>{{ s.options_criteria }}</td>
                <td>
                  <template v-if="!s.num"><span>{{ s.num }}</span></template>
                  <template v-else><span name="deliveryresultnum" class="download-link" @click="download(s.inhaisouno)">{{ s.num }}</span></template>
                </td>
                <td>
                  <template v-if="!s.inreceivenum"><div>到:<span>{{ s.inreceivenum }}</span></div></template>
                  <template v-else><div>到:<span name="inreceivenum" class="download-link" @click="download(s.inhaisouno)">{{ s.inreceivenum }}</span></div></template>
                  <template v-if="!s.inerrornum"><div>エ:<span name="inerrornum">{{ s.inerrornum }}</span></div></template>
                  <template v-else><div>エ:<span name="inerrornum" class="download-link" @click="download(s.inhaisouno)">{{ s.inerrornum }}</span></div></template>
                  <template v-if="!s.inunsubscribenum"><div>停:<span>{{ s.inunsubscribenum }}</span></div></template>
                  <template v-else><div>停:<span name="inunsubscribenum" class="download-link" @click="download(s.inhaisouno)">{{ s.inunsubscribenum }}</span></div></template>
                </td>
                <td>
                  <template v-if="!s.inclicknum"><div>ク:<span name="inclicknum">{{ s.inclicknum }}</span></div></template>
                  <template v-else><div>ク:<span name="inclicknum" class="download-link" @click="download(s.inhaisouno)">{{ s.inclicknum }}</span></div></template>
                  <template v-if="!s.intrackingnum"><div>追:<span>{{ s.intrackingnum }}</span></div></template>
                  <template v-else><div>追:<span name="intrackingnum" class="download-link" @click="download(s.inhaisouno)">{{ s.intrackingnum }}</span></div></template>
                </td>
                <td>
                  <template v-if="!s.inenqviewnum"><div>閲:<span name="inenqviewnum">{{ s.inenqviewnum }}</span></div></template>
                  <template v-else><div>閲:<span  name="inenqviewnum" class="download-link" @click="download(s.inhaisouno)">{{ s.inenqviewnum }}</span></div></template>
                  <template v-if="!s.inenqansnum"><div>回:<span name="inenqansnum">{{ s.inenqansnum }}</span></div></template>
                  <template v-else><div>回:<span  name="inenqansnum" class="download-link" @click="download(s.inhaisouno)">{{ s.inenqansnum }}</span></div></template>
                </td>
                <td>
                  <template v-if="s.inmailclicknum == '-'"><div>文:<span name="inmailclicknum">0</span></div></template>
                  <template v-else><div>文:<span name="inmailclicknum" class="download-link" @click="download(s.inhaisouno)">{{s.inmailclicknum}}</span></div></template>
                  <template v-if="s.indirecthpnum == '-'"><div>直:<span name="indirecthpnum">0</span></div></template>
                  <template v-else><div>直:<span name="indirecthpnum" class="download-link" @click="download(s.inhaisouno)">{{ s.indirecthpnum}}</span></div></template>
                  <template v-if="s.inscrollnum == '-'"><div>計:<span name="inscrollnum">0</span></div></template> 
                  <template v-else><div>計:<span name="inscrollnum" class="download-link" @click="download(s.inhaisouno)">{{s.inscrollnum}}</span><span><a :href="go_page(s)" target="_blank"><font-awesome-icon icon="fas fa-list-alt"/></a></span></div></template>
                  <template v-if="s.invideowatchnum == '-'"><div>動:<span name="invideowatchnum">0</span></div></template>
                  <template v-else><div>動:<span name="invideowatchnum" class="download-link" @click="download(s.inhaisouno)">{{ s.invideowatchnum }}</span></div></template>
                </td>
              </template>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal fade help" id="preview_modal" tabindex="-1" aria-labelledby="" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="previewModalLabel">原稿プレビュー</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col-xxl mb-2">
            <div class="form">
              <div class="input_area">
                <div class="mb-3">
                  <input type="text" class="form-control" id="exampleFormControlInput2" v-model="article.stsubject" prop="stsubject" placeholder="" readonly>
                </div>
                <div class="mb-3 sentence" id="mailbody">
                  <textarea v-model="article.stbunmen" prop="stbunmen" class="form-control" id="txtMailBody" rows="22"  readonly></textarea>
                </div>
                <div id="from" class="mb-3">
                  <label for="exampleFormControlInput3" class="form-label">配信元</label>
                  <input type="text" class="form-control" id="exampleFormControlInput3" v-model="article.sthaishinmoto" prop="sthaishinmoto" readonly>
                </div>
                <div id="mail" class="mb-3">
                  <label for="exampleFormControlInput4" class="form-label">メール</label>
                  <input type="email" class="form-control" id="exampleFormControlInput4" v-model="article.stfrom" prop="stfrom" readonly >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="reserve_modal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div v-if="change_sch" class="modal-header">
          <h5 class="modal-title"> 新しく配信を予約<span class="me-2"/>/<span class="me-2"/>スケジュールを変更</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="r_m_reset"></button>
        </div>
        <div v-else class="modal-header">
          <h5 class="modal-title">新しく配信を予約</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="r_m_reset"></button>
        </div>
        <div class="modal-body">
          <div>
            ■配信リストを選択
            <div class="form-check">
              <input class="form-check-input" type="radio" name="mailType" id="mailtype_all" value="all" v-model="mailkind" @change="switch_select($event.currentTarget.value)">
              <label class="form-check-label" for="mailtype_all">全員配信(登録されている全員に配信)</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="mailType" id="mailtype_query" value="query" v-model="mailkind" @change="switch_select($event.currentTarget.value)">
              <label class="form-check-label" for="mailtype_query">クエリー配信(指定したクエリーの対象に配信)</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="mailType" id="mailtype_csv" value="csv" v-model="mailkind" @change="switch_select($event.currentTarget.value)">
              <label class="form-check-label" for="mailtype_csv">CSV配信(アップロードしたリスト内の対象に配信)</label>
            </div>
            <div class=""  v-show="query_area">
              <div class="">
                <label class="" for="query_kind">種別</label>
                <div class="">
                  <select class="form-select" id="query_kind" v-model="querykind" @change="get_queries">
                    <option value="customer">顧客情報</option><option value="follow">フォロー情報</option><option value="appointment">外出情報</option><option value="project">案件情報</option><option value="estimate">見積書</option><option value="invoice">請求書</option><option value="enqueteview">アンケート閲覧</option><option value="enqueteanswer">アンケート回答</option><option value="urlview">クリック測定</option><option value="sentmail">メール配信</option><option value="confirmmail">ステップ配信予定</option><option value="event">イベント情報</option><option value="tracking">追跡</option>
                  </select>
                </div>
              </div>
              <div class="">
                <div v-show="show_query_name">
                  <label class="" for="query">クエリ―名</label>
                  <div>
                    <select id="query" class="form-select" v-model="querynumber" @change="get_query_info">
                      <option v-for="q in queries" :key="q.inno" :value="q.inno">{{ q.stnamae }}</option>
                    </select>
                  </div>
                  <div class="m-1">
                    <table class="border border-1" style="width: 100%;">
                      <tr v-for="(cond, label) in get_query_info_cond()" :key="label">
                        <th>{{ label }}</th>
                        <td class="text-start">{{ cond }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div v-show="noquery">クエリーが存在しません</div>
              </div>
            </div>
            <div class="" v-show="csv_area">
              <div class="input-group">
                <input type="file" class="form-control" id="inputGroupFile04" accept=".csv"   aria-describedby="inputGroupFileAddon04" aria-label="Upload">
                <button class="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04" @click="upload_file" >アップロード</button>
              </div>
              <div v-show="csv_list">
                <div class="csv_list">
                  <table>
                    <thead>
                      <tr>
                        <th>ファイル名</th>
                        <th>登録日時</th>
                        <th>配信件数</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="f in files" :key="f.filename">
                        <td>{{ f.filename }}</td>
                        <td>{{ f.date }}</td>
                        <td>{{ f.result.num}}件</td>
                      </tr>
                      <tr v-for = "f in display_files" :key="f.name">
                        <td>{{ f.name }}</td>
                        <td>{{ f.date }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class= text-end>
                  <button class="btn btn-secondary" id="delete_file" @click="delete_file">リストを全削除</button>
                </div>
              </div>
            </div>            
            <!--
              <div class="form-check">
              <input class="form-check-input" type="radio" name="mailType" id="mailtype_csv" value="all" v-model="mailkind">
              <label class="form-check-label" for="mailtype_all">全員配信(登録されている全員に配信)</label>
              </div>
            -->
          </div>
          <div>
            ■配信基準設定
            <div>
              同一メールアドレスへの配信方法
              <div class="form-check ms-1">
                <input class="form-check-input" type="radio" name="criteria_customer" id="criteria_all" value="all" checked v-model="criteria_email">
                <label class="form-check-label" for="criteria_all">顧客No基準</label>
              </div>
              <div class="form-check ms-1">
                <input class="form-check-input" type="radio" name="criteria_customer" id="criteria_one" value="one" v-model="criteria_email">
                <label class="form-check-label" for="criteria_one">メールアドレス基準</label>
              </div>
            </div>
            <div>
              追加データに関する配信方法
              <div class="form-check ms-1">
                <input class="form-check-input" type="radio" name="criteria_target" id="criteria_include" value="include" checked v-model="criteria_onthefly">
                <label class="form-check-label" for="criteria_include">配信時基準</label>
              </div>
              <div class="form-check ms-1">
                <input class="form-check-input" type="radio" name="criteria_target" id="criteria_exclude" value="exclude" v-model="criteria_onthefly">
                <label class="form-check-label" for="criteria_exclude">登録時基準</label>
              </div>
            </div>
          </div>
          <div>
            ■配信日時
            <Datepicker v-model="delivery_datetime" :start-time="startTime" time-picker-inline format="yyyy/MM/dd HH:mm" locale="ja" cancelText="Cancel" selectText="OK" minutes-increment="5" @open="set_startTime"></Datepicker>
          </div>
        </div>
        <div v-if="change_sch" class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="r_m_reset">キャンセル</button>
          <button type="button" class="btn btn-primary " id="add" @click="save($event)" >新しく予約</button>
          <button type="button" class="btn btn-danger btn-lg"  id="change" @click="save($event)">変更</button>

           </div>
                <div v-else class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="r_m_reset">キャンセル</button>
          <button type="button" class="btn btn-primary" id="add" @click="save($event)" >確定</button>
        </div>

      </div>
    </div>
  </div>
  <div class="modal fade help" id="main_help" tabindex="-1" aria-labelledby="main_help" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title bold" id="main_help">配信予定・結果<span class="me-2"/>ヘルプページ</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <span class="section">目次</span>
          <div class="section-box">
            <div class="section-item"><a href="#explain">全般説明</a></div>
            <div class="section-item"><a href="#delivery_info"> 配信No.・原稿名 ・配信リスト</a></div>
            <div class="section-item"><a href="#criteria_and_num">基準<span class="me-2"/>配信(予定)数</a></div>
            <div class="section-item"><a href="#result">配信結果</a></div>
            <div class="section-item"><a href="#click_trcking">効果測定</a></div>
            <div class="section-item"><a href="#enq">アンケート</a></div>
            <div class="section-item"><a href="#important">確実な閲覧</a></div>
          </div>
          <hr>
          <span class="section" id="explain">全般説明</span>
          <p>この画面では、メール配信の結果とスケジュールを確認できます。<br>メール配信の結果/スケジュールは、配信(予定)日時の新しいものから順に表示されます。配信スケジュールについては、配信予定日時が<span class="me-2"/><font-awesome-icon icon="fa-solid fa-square" class="fa-lg orange"/><span class="me-2"/>の太字で表示されます。<br>配信結果/スケジュールは、期間と原稿名から絞り込み検索ができます。<br>各配信結果については、<span class="download-link" disabled></span><span class="me-2"/>からCSVファイルでダウンロードすることができ、確度の高い顧客の情報を確認できます。</p>
          <hr>
          <span class="section" id="delivery_info">配信No.・原稿名・配信リスト</span>
          <p>この列では、配信結果/スケジュールの詳細が表示されます。</p>
          <table class="modal_table">
            <thead>
              <tr>
                <th>行</th><th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>配信No.</td><td>その配信結果/スケジュール固有の番号</td></tr>
              <tr><td>原稿名</td><td>配信した/する原稿の名前。原稿名をクリックすると、リンクから原稿のプレビューが見られる</td></tr>
              <tr><td>配信リスト</td><td>配信予約時に選択した配信リストの詳細</td></tr>
            </tbody>
          </table>
          <br>
          <p>また、列の左側のアイコンから、スケジュールや原稿に対して操作を行えます。</p>
          <span><font-awesome-icon icon="fa-solid fa-edit" class="fa-lg blue"/></span>
          <p>原稿設定画面に移動して、原稿の編集ができます。</p>
          <br>
          <span><font-awesome-icon icon="fa-solid fa-calendar-alt" @click="change_schedule(s)" class="fa-lg"/></span>
          <p>新たな配信の予約ができます。予約の設定は、原稿設定画面での配信予約と同じ操作方法です。<br>未配信のスケジュールについては操作が2種類あり、<button type="button" class="btn btn-danger btn-sm" disabled>変更</button><span class="me-2"/>をクリックすると、配信スケジュールの変更ができ、<button type="button" class="btn btn-primary btn-sm" disabled>新しく予約</button><span class="me-2"/>をクリックすると、現在の配信スケジュールとは別の配信スケジュールを追加で予約できます。</p>
          <br>
          <span><font-awesome-icon icon="fa-solid fa-trash" class="fa-lg"/></span>
          <p>配信スケジュールを削除できます。未配信のスケジュールにのみ表示されます。</p>
          <hr>
          <span class="section" id="criteria_and_num">基準<span class="me-2"/>配信(予定)数</span>
          <p>「基準」の列では、配信予約時に設定した配信基準が表示されます。配信基準は、以下の2種類です。</p>
          <table class="modal_table">
            <thead>
              <tr>
                <th>配信基準</th><th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>「顧客No」基準</td><td>→同一のメールアドレスでも顧客Noが違えば全てに配信する</td></tr>
              <tr><td>「メールアドレスNo」基準</td><td>同一のメールアドレスなら配信時に一番新しい登録(更新日時)にのみ配信する</td></tr>
            </tbody>
          </table>

          <br>
          <p>「配信(予定)数の列」では、配信予約時に設定した配信先の人数が表示されます。<span class="me-2"/><span class="download-link" disabled></span><span class="me-2"/>をクリックすると、配信(予定)数のCSVリストをダウンロードでき、リストの中身が確認できます。</p>
          <hr>
          <span class="section" id="result">配信結果</span>
          <p>この列では、メール配信の結果が表示されます。<br>各行の<span class="me-2"/><span class="download-link" disabled></span><span class="me-2"/>をクリックすると、CSVリストをダウンロードできます。</p>
          <table class="modal_table">
            <thead>
              <tr>
                <th>行</th><th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>到達</td><td>配信したメールが無事到達した人の合計。「到:」で表示</td></tr>
              <tr><td>エラー</td><td>配信したメールが届かずエラーになった人の合計。「エ:」で表示</td></tr>
              <tr><td>停止</td><td>メール配信の登録解除をクリックした人の合計。「停:」で表示</td></tr>
            </tbody>
          </table>
          <hr>
          <span class="section" id="click_trcking">効果測定</span>
          <p>この列では、メール配信後URLクリックやページ訪問など何かしらのリアクションがあった人の数が表示されます。<br>各行の<span class="me-2"/><span class="download-link" disabled></span><span class="me-2"/>をクリックすると、CSVリストをダウンロードできます。</p>
          <table class="modal_table">
            <thead>
              <tr>
                <th>行</th><th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>クリック</td><td>メール本文のクリック測定URLをクリックした人の合計。「ク:」で表示</td></tr>
              <tr><td>追跡</td><td>メール配信後にどこかのページへのアクセスがあった人の合計。「追:」で表示</td></tr>
            </tbody>
          </table>

          <hr>
          <span class="section" id="enq">アンケート</span>
          <p>この列では、メール文面にアンケートページへのURLを貼ったとき、アンケート閲覧、アンケート回答した人の数が表示されます。<br>各行の<span class="me-2"/><span class="download-link" disabled></span><span class="me-2"/>をクリックすると、CSVリストをダウンロードできます。</p>
          <table class="modal_table">
            <thead>
              <tr>
                <th>行</th><th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>閲覧</td><td>メール本文のURLからアンケートページを閲覧した人の合計。「閲:」で表示</td></tr>
              <tr><td>回答</td><td>アンケートページで回答した人の合計。「回:」で表示</td></tr>
            </tbody>
          </table>

          <hr>
          <span class="section" id="important">確実な閲覧</span>
          <p>この列では、メール配信後一週間以内にどこかのページでスクロールを３回以上している人を抽出して表示しています。<br>アンチウィルスソフトが勝手にクリックしたものやクリックして何も見ずに閉じた人は排除され、ページを「確実に閲覧」した人だけを抽出しているので、最も確度の高いリストです。<br>各行の<span class="me-2"/><span class="download-link" disabled></span><span class="me-2"/>をクリックすると、CSVリストをダウンロードできます。</p>
          <table class="modal_table">
            <thead>
              <tr>
                <th>行</th><th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>文中クリック</td><td>メール文面中のURLをクリックしてページへ来た人の合計。「文:」で表示</td></tr>
              <tr><td>直接㏋</td><td>メール本文中のリンクをクリックせず、検索やブックマークなどからページへ来た人の合計。「直:」で表示</td></tr>
              <tr><td>合計</td><td>文中クリックと直接㏋の合計。「計:」で表示。<font-awesome-icon icon="fas fa-list-alt" class="blue"/><span class="me-2"/>をクリックすると、ページ閲覧分析のページに移動する</td></tr>
              <tr><td>動画視聴</td><td>自社ページ内にYouTube動画が埋め込んである場合、その動画を視聴した人の合計。「動:」で表示</td></tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js'
import store from '@/store'
export default {
  name: 'DeliveryScheduleResultView',
  data() {
    return {
      schedules: [],
      mailkind: '',
      querykind: 'customer',
      querynumber: '',
      queries: [],
      query_info: {},
      article: {},
      article_org: {},
      articles: [],
      articleno: null,
      startTime: {},
      delivery_datetime: null,
      criteria_onthefly: 'include',
      criteria_email: 'all',
      reserve_modal: null,
      r_m: null,
      change_sch: false,
      skoushin: {},
      query_area: false,
      noquery: false,
      show_query_name: true,
      csv_area: false,
      csv_list: false,
      files: [],
      display_files: [],
      from: 0,
      num: 10,
      numall: 0,
      script_name: null,
      delivery_date_for_search: null,
      after_script_name: null,
      after_delivery_date_for_search: null,
      update_schedule: false,
      page_data: {},
      start_this_month: null,
      end_this_month: null,
      start_last_month: null,
      end_last_month: null
    }
  },
  mounted() {
    this.get_schedule_and_result(false, false, false)
    this.reserve_modal = document.getElementById('reserve_modal')
    this.r_m = new bootstrap.Modal(this.reserve_modal, {})
    let element = document.getElementById('infinite_scroll')
    element.addEventListener('scroll', this.on_scroll)
  },
  methods: {
     on_edit() {
       window.location.href = '/edit'
    },
    download(inhaisouno) {
      let e = event.currentTarget.getAttribute("name")
      let d = new Date()
       if(e.match(/deliveryschedulenum/)) {
        let filename = 'Mailrecipient_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
        this.$crmapi.download_csv(
          {
            method: 'search',
            kind: 'mailrecipient',
            mode: 'download',
            cond: JSON.stringify({ inhaisouno: inhaisouno, sort: 'inmemberno', order: 'asc' }),
            catalog: 'mail',
            format: 'csv',
          },
          filename
        )
      }

      if(e.match(/deliveryresultnum/)) {
        let filename = 'Sentmail_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
        this.$crmapi.download_csv(
          {
            method: 'search',
            kind: 'sentmail',
            mode: 'download',
            cond: JSON.stringify({ inhaisouno: inhaisouno, sort: 'inmemberno', order: 'asc' }),
            catalog: 'sentmail',
            format: 'csv',
          },
          filename
        )
      }

      if(e.match(/inreceivenum/)) {
        let filename = 'Sentmail_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
        this.$crmapi.download_csv(
          {
            method: 'search',
            kind: 'sentmail',
            mode: 'download',
            cond: JSON.stringify({ inhaisouno: inhaisouno, berror:false,  sort: 'inmemberno', order: 'asc' }),
            catalog: 'sentmail',
            format: 'csv',
          },
          filename
        )
      }
      if(e.match(/inerrornum/)) {
        let filename = 'Sentmail_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
        this.$crmapi.download_csv(
          {
            method: 'search',
            kind: 'sentmail',
            mode: 'download',
            cond: JSON.stringify({ inhaisouno: inhaisouno, berror:false,sort: 'inmemberno', order: 'asc' }),
            catalog: 'sentmail',
            format: 'csv',
          },
          filename
        )
      }

      if(e.match(/inunsubscribenum/)) {
        let filename = 'Sentmail_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
        this.$crmapi.download_csv(
          {
            method: 'search',
            kind: 'sentmail',
            mode: 'download',
            cond: JSON.stringify({ inhaisouno: inhaisouno, bunsubscribe:true, sort: 'inmemberno', order: 'asc' }),
            catalog: 'sentmail',
            format: 'csv',
          },
          filename
        )
      }
      if(e.match(/inclicknum/)) {
        let filename = 'Urlview_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
        this.$crmapi.download_csv(
          {
            method: 'search',
            kind: 'urlview',
            mode: 'download',
            cond: JSON.stringify({ inhaisouno: inhaisouno, alleigyou:true,  sort: 'mailresult', order: 'asc' }),
            catalog: 'urlview',
            format: 'csv',
          },
          filename
        )
      }
      if(e.match(/intrackingnum/)) {
        let filename = 'Tracking_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
        this.$crmapi.download_csv(
          {
            method: 'search',
            kind: 'tracking',
            mode: 'download',
            cond: JSON.stringify({ inhaisouno: inhaisouno, alleigyou:true, sort: 'mailresult', order: 'asc' }),
            catalog: 'tracking',
            format: 'csv',
          },
          filename
        )
      }
      if(e.match(/inenqviewnum/)) {
        let filename = 'Enqueteview_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
        this.$crmapi.download_csv(
          {
            method: 'search',
            kind: 'enqueteview',
            mode: 'download',
            cond: JSON.stringify({ inhaisouno: inhaisouno, alleigyou:true, sort: 'mailresult', order: 'asc' }),
            catalog: 'enqueteview',
            format: 'csv',
          },
          filename
        )
      }
      if(e.match(/inenqansnum/)) {
        let filename = 'Enqueteanswer_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
        this.$crmapi.download_csv(
          {
            method: 'search',
            kind: 'enqueteanswer',
            mode: 'download',
            cond: JSON.stringify({ inhaisouno: inhaisouno, alleigyou:true, sort: 'mailresult', order: 'asc' }),
            catalog: 'enqueteanswer',
            format: 'csv',
          },
          filename
        )
      }
      if(e.match(/inmailclicknum/)) {
        let filename = 'Mailclick_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
        this.$crmapi.download_csv(
          {
            method: 'search',
            kind: 'mailclick',
            mode: 'download',
            cond: JSON.stringify({ inhaisouno: inhaisouno, mailclick:true }),
            catalog: 'mailresult',
            format: 'csv',
          },
          filename
        )
      }
      if(e.match(/indirecthpnum/)) {
        let filename = 'Directhp_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
        this.$crmapi.download_csv(
          {
            method: 'search',
            kind: 'directhp',
            mode: 'download',
            cond: JSON.stringify({ inhaisouno: inhaisouno, directhp:true }),
            catalog: 'mailresult',
            format: 'csv',
          },
          filename
        )
      }
      if(e.match(/inscrollnum/)) {
        let filename = 'Mailscroll_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
        this.$crmapi.download_csv(
          {
            method: 'search',
            kind: 'mailscroll',
            mode: 'download',
            cond: JSON.stringify({ inhaisouno: inhaisouno }),
            catalog: 'mailresult',
            format: 'csv',
          },
          filename
        )
      }
      if(e.match(/invideowatchnum/)) {
        let filename = 'Videowatch_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
        this.$crmapi.download_csv(
          {
            method: 'search',
            kind: 'videowatch',
            mode: 'download',
            cond: JSON.stringify({ inhaisouno: inhaisouno, videowatch:true }),
            catalog: 'mailresult',
            format: 'csv',
          },
          filename
        )
      }
    },
    open_preview_modal(s) {
      this.$crmapi.callApi(
        {
          method: 'get',
          kind: 'mailarticle',
          no: s.inmailtmplno
        }
      ).then((response) => {
        this.article = response.result
        this.article_org = JSON.parse(JSON.stringify(this.article))
        let inputs = document.querySelectorAll('input[prop], textarea[prop], select[prop]')
        for (let input of inputs) {
          this.set_changed(input)
        }
      })
    },
    set_changed(elem) {
      let prop = elem.getAttribute('prop')
      if (this.article[prop] == this.article_org[prop]) {
        elem.className = elem.className.replace(/changed/g, '')
      } else if (!~elem.className.indexOf('changed')) {
        elem.className += ' changed'
      }
    },
    show_info() {
      //this.reserve_modal.removeEventListener('hidden.bs.modal', this.show_info, true)
      //bootstrap.Modal.getInstance(this.info_modal).show()
    },
     set_startTime() {
        this.startTime = { hours: new Date().getHours(), minutes: Math.floor(((new Date().getMinutes())/5)+1)*5%60 }
      },
    format_datetime(d) {
      if (!d) {
        return ''
      }
      return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()
    },
    format_date(ds) {
      if (!ds) {
        return ''
      }
      if (ds[1]) {
        let start_date = ds[0].getFullYear() + '/' + (ds[0].getMonth() + 1) + '/' + ds[0].getDate()
        let end_date = ds[1].getFullYear() + '/' + (ds[1].getMonth() + 1) + '/' + ds[1].getDate()
        return start_date + ' - ' + end_date
      } else {
        let start_date = ds[0].getFullYear() + '/' + (ds[0].getMonth() + 1) + '/' + ds[0].getDate()
        return start_date + ' - '
      } 
    },
    get_articles() {
      return this.$crmapi.callApi(
        {
          method: 'search',
          mode: 'search',
          kind: 'mailarticle',
          cond: JSON.stringify({ order: 'desc' })
        }
      ).then((response) => {
        this.articles = response.result
      })
    },
    change_schedule(s) {
      this.skoushin = s
      this.display_schedule(s)
      this.change_sch = true
    },
    add_schedule(s) {
      this.skoushin = s
    },
    display_schedule(schedule){
      this.mailkind = schedule.sttype
      this.display_select(schedule)
      let dd = new Date(schedule.tihaisou)
      this.delivery_datetime = dd
    },
    display_select(schedule) {
      if (schedule.sttype == 'query') {
        this.query_area = true
        let stob = JSON.parse(schedule.stlist)
        this.display_query_info(stob)
      } else if(schedule.sttype =='csv') {
        this.csv_area = true
        this.csv_list = true
        this.query_area = false
        this.display_csv_info(schedule)
      } else {
        this.csv_area = false
        this.query_area = false
      }
    },
    display_query_info(query) {
      this.querykind = query.kind
      this.querynumber = query.no
      this.$crmapi.callApi(
        {
          method: 'list',
          kind: 'query',
          type: this.querykind,
        }
      ).then((response) => {
        this.queries = response.result
      })
      this.$crmapi.callApi(
        {
          method: 'get',
          kind: 'query',
          no: this.querynumber

        }
      ).then((response) => {
        this.query_info = response.result
      }) 
    },
    display_csv_info(s) {
      let stob = JSON.parse(s.stlist)
      this.display_files = stob
    },
    switch_select(type) {
      if(this.files.length > 0 && type !=='csv'||this.display_files.length > 0 && type !=='csv') {
        if (!confirm('配信リストの種類を変更すると、アップロード済みのCSVリストはすべて削除されます。よろしいですか?')) {
          return
        }
        this.files = []
        this.display_files = []
        this.csv_list = false
      }
      if (type == 'query') {
        this.query_area = true
        this.csv_area = false
        this.get_queries()
      } else if(type == 'csv') {
        this.query_area = false
        this.csv_area = true
      }else{
        this.query_area = false
        this.csv_area = false
      }
    },
    get_query_info() {
      if (!this.queries[0]) {
        this.noquery = true
        this.show_query_name = false
      }else{
        this.noquery = false
        this.show_query_name = true
        this.querynumber = this.queries[0].inno
        this.$crmapi.callApi(
          {
            method: 'get',
            kind: 'query',
            no: this.querynumber
          }
        ).then((response) => {
          this.query_info = response.result
        })
        let qno = document.getElementById('query').selectedIndex
        if(qno >=0) {
          this.querynumber =  this.queries[qno].inno
          this.$crmapi.callApi(
            {
              method: 'get',
              kind: 'query',
              no: this.querynumber
            }
          ).then((response) => {
            this.query_info = response.result
          }) 
        }
      }
    },
    get_queries() {
      this.$crmapi.callApi(
        {
          method: 'list',
          kind: 'query',
          type: this.querykind,
        }
      ).then((response) => {
        this.queries = response.result
        setTimeout(() => this.get_query_info(), 100)
      })
    },
    get_query_info_cond() {
      if (this.query_info) {
        return this.query_info.cond
      } else {
        return {}
      }
    },
    upload_file() {
      var f = document.getElementById("inputGroupFile04").files
      if(!f[0]) {
        alert("アップロードするファイルを指定してください")
        return
      }
      if(f[0].size > 2097152) {
        alert("ファイルサイズが2MBを超えています")
        return
      }
      var i = {}
      var data = new FormData()
      data.append("MAX_FILE_SIZE", 2097152)
      data.append("filename",f[0],)
      data.append("token",store.state.token,)
      data.append("apiname","upload")
      data.append("kind","mailrecipient",)
      data.append( "info",JSON.stringify(i) )
      var config = {
        headers: {
          "content-type": "multipart/form-data;"
        }
      }
      this.axios.post(this.$crmapi.ENDPOINT, data, config) 
        .then((response)=> {
          alert(response.data.result.num + "件の配信先が登録されました。") 
          let d = new Date()
          let fd = this.format_datetime(d)
          response.data.date = fd
          this.files.push(response.data)
          this.csv_list = true
        })
    },
    delete_file() {
      if (!confirm('リストを全削除します。よろしいですか？')) {
        return
      }
      this.files = []
      this.display_files = []
      this.csv_list = false
    },
    save(event) {
      let e =   event.currentTarget.id
      try {
        if (!this.delivery_datetime) {
          throw new Error('配信日時を設定してください。')
        }
        let after5min = new Date()
        after5min.setTime(after5min.getTime() + 300000) // 5分後
        let dd = new Date(this.delivery_datetime)
        if (!this.delivery_datetime || this.delivery_datetime.getTime() < after5min.getTime() || dd.getTime()  < after5min.getTime()) {
          throw new Error   ('配信日時は現在から5分後以降の日時を設定してください。')   
        }
        var type = ''
        if (document.getElementById('mailtype_all').checked) {
          type = 'all'
        } else if (document.getElementById('mailtype_query').checked) {
          type = 'query'
          var querykind = document.getElementById('query_kind').value
          if (!querykind) {
            throw new Error('クエリ種別を選択してください。')
          }
          var queryno = document.getElementById('query').value
          if (!queryno) {
            throw new Error('クエリ名を選択してください。')
          }
          var select = document.getElementById('query')
          var option = select.options[select.selectedIndex]
          var queryname = option.innerText
        } else if (document.getElementById('mailtype_csv').checked) {
          type = 'csv'
          if(this.files.length < 1 && this.display_files.length  < 1) {
            throw new Error("配信先CSVファイルを少なくともひとつ以上登録してください。")
          } 
        } else {
          throw new Error('配信リストを選択してください。')
        }
      } catch(e) {
        alert(e.message)
        return
      }
      if (e=="add")  {                                               
        var info = {
          stoption:{
            criteria_email: document.getElementById('criteria_one').checked,
            onthefly: document.getElementById('criteria_include').checked
          },
          sttype: type,
          inmailtmplno: this.skoushin.inmailtmplno,
          tihaisou: this.format_datetime(this.delivery_datetime),
          ingmtminute: -540
        }
        if (type == 'query') {
          info.stlist = {
            "name": queryname,
            "no": queryno,
            "kind": querykind,
          }
        }
        if (type == 'csv') {
          let ilist = []
          for(let f of this.files){
            let d = new Date()
            let date =  this.format_datetime(d)
            let st = 
              {
                "name": f.filename,
                "date": date,
                "no": f.result.inrecipientno
              }
            ilist.push(st)
          }
          if (this.display_files.length > 0 ) {
            ilist = ilist.concat(this.display_files)
          } 
          info.stlist = ilist
        }         
        if (!confirm('この内容で配信予約します。よろしいですか？')) {
          return
        }
        this.$crmapi.callApi(
          {
            method: 'add',
            kind: 'mailschedule',
            info: JSON.stringify(info),
            no: 0,
            mode: 'partial',
          }
        ).then(() => {
          this.r_m_reset()
          this.r_m.hide()
          this.get_schedule_and_result(false, false, true)
        })
      } 
      else {
        var c_info = {
          stoption:{
            criteria_email: document.getElementById('criteria_one').checked,
            onthefly: document.getElementById('criteria_include').checked
          },
          sttype: type,
          inmailtmplno: this.skoushin.inmailtmplno,
          tihaisou: this.format_datetime(this.delivery_datetime),
          ingmtminute: -540,
          inhaisouno : this.skoushin.inhaisouno
        }
        if (type == 'query') {
          c_info.stlist = {
            "name": queryname,
            "no": queryno,
            "kind": querykind,
          }
        }
        if (type == 'csv') {
          let ilist = []
          for(let f of this.files){
            let d = new Date()
            let date =  this.format_datetime(d)
            let st = 
              {
                "name": f.filename,
                "date": date,
                "no": f.result.inrecipientno
              }
            ilist.push(st)
          }
          if (this.display_files.length > 0 ) {
            ilist = ilist.concat(this.display_files)
          } 
          c_info.stlist = ilist
        }         
        if (!window.confirm('この内容で変更してもよろしいでしょうか?')) {
          return
        }
        this.$crmapi.callApi(
          {
            method: 'update',
            kind: 'mailschedule',
            info: JSON.stringify(c_info),
            no: this.skoushin.inhaisouno,
            mode: 'partial',
          }
        ).then(() => {
          this.r_m_reset()
          this.r_m.hide()
          this.get_schedule_and_result(false, false, true)
        })
      }
    },
    r_m_reset() {
      this.mailkind = ''
      this.querykind = 'customer'
      this.queries = []
      this.query_info = {}
      this.query_area = false
      this.noquery = false
      this.show_query_name = true
      this.csv_area = false
      this.csv_list = false
      this.files = []
      this.display_files = []
      this.delivery_datetime = null
      this. criteria_onthefly = 'include'
      this.criteria_email = 'all'
      this.change_sch = false
    },
    delete_schedule(sno){
      if (!window.confirm("No."+ sno +"の配信設定を削除します。よろしいでしょうか?")) {
        return
      }
      this.$crmapi.callApi(
        {
          method: 'delete',
          kind: 'mailschedule',
          no: sno,
          catalog: 'mail'
        }
      ).then((response) => {
        if (response.retval != 0) {
          alert(response.message)
          return
        }
        this.get_schedule_and_result(false, false, true)
      })
    },
    get_schedule_and_result(click = false, add = false, reload=true) {
      let script_name = '';
      let delivery_date_for_search = '';
      if (click) {
        script_name = this.script_name;
        delivery_date_for_search = this.delivery_date_for_search;
        this.after_script_name = this.script_name;
        this.after_delivery_date_for_search = this.delivery_date_for_search;
        this.from = 0
        let element = document.getElementById('infinite_scroll')
        element.scroll({ top:0, behavior: 'smooth' });
      }
      if (reload) {
        this.from = 0
              }
      if (this.after_script_name) {
        script_name = this.after_script_name;
      }
      if (this.after_delivery_date_for_search) {
        delivery_date_for_search = this.after_delivery_date_for_search;
      }
      this.$crmapi.callApi(
        {
          method: 'search',
          mode: 'search',
          kind: 'mailresult',
          cond: JSON.stringify({ sort: 'tihaisou', order: 'desc', mode: 'all', stsubject: script_name, stperiod: this.format_date(delivery_date_for_search) }),
          num: this.num,
          from: this.from,
        }
      ).then((response) => {
        this.numall = response.numall;
        if (add) {
          this.schedules.pop()
          response.result.forEach((sendlist) => {
            this.schedules.push(sendlist);
          });
          this.update_schedule = false ;
        } else {
          this.schedules = response.result
        }
      })
    },
    on_scroll () {
      let client_height = this.$refs.scroll_area.clientHeight
      let scroll_height = this.$refs.scroll_area.scrollHeight
      let scroll_top = this.$refs.scroll_area.scrollTop
      if (scroll_height - (client_height + scroll_top) < 10) {
        if (this.from < this.numall) {
          // 全部読み込んでいない場合
          if (0 <= (this.schedules.length - this.num)) {
            if (this.update_schedule) { return; }
            this.from += 10
            this.update_schedule = true;
            this.schedules.push({"inhaisouno": "-1"})
            this.get_schedule_and_result(false, true, false)
          }
        }
      }
    }
  },
  computed: {
    get_edit_page_url() {
      return (s) => {                                    
        return s.inmailtmplno ? `/edit?no=${s.inmailtmplno}` : '/edit'
      }
    },
    go_page() {
      return (s) => {                                    
        return  process.env.VUE_APP_API_ORIGIN + `/crm/2.0/ja/pageanalysis.php?inhaisouno=${s.inhaisouno}`
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.list {
  border: solid 1px #d5d5d5;
  border-radius: 2px;
  box-shadow: 2px 4px 10px 0px #d5d5d5;
  display: inline-block;
  padding: 10px;
  width: 98%;
  margin-bottom: 10px;
}
.list > div {
  display: flex;
  align-items: baseline;
}
.title {
  display: inline-block;
  font-size: 1.75rem;
  margin-bottom: -1px;
  border: solid 1.5px #d5d5d5;
  border-bottom: none;
  padding: 5px 20px;
  position: relative;
  background-color: white;
}
.list .search {
  display: inline-flex;
  margin-right: auto;
  align-items: baseline;
}
.list .search input {
  margin: 5px;
}
.list .search div.dp__main {
  margin: 0 5px;
  width: 260px;
}
.list .m-2 table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0; 
  table-layout: fixed;
}
.list #infinite_scroll {
  max-width: calc(100vw - 70px);
  overflow-y: auto;
  height: calc(100vh - 250px);
}
.list #infinite_scroll table thead {
  position: sticky;
  top: 0;
  background: white;
  white-space: nowrap;
  text-align: center;
}
.list .m-2 .future td:nth-child(1) {
  color: #ff9800;
  font-weight: bold;
}
.list .m-2 table {
  border-collapse: collapse;
}
.list .m-2 table thead th {
  z-index: 10;
  padding: 4px;
  border: 1px solid #d5d5d5;
  background: #d5d5d54f;
  font-weight: normal;
  vertical-align: middle;
}
.list .m-2 tr:first-child th:after,
.list .m-2 tr:first-child th:before {
  content: "";
  position: absolute;
  left: -1px;
  width: 100%;
  height: 100%;
}
.list .m-2 tr:first-child th:before {
  top: -3px;
  border-top: 2px solid currentColor;
}
.list .m-2 tr:first-child th:after {
  top: 1px;
  border-bottom: 2px solid currentColor;
}
.list .m-2 table tbody td {
  padding: 5px;
  border: 1px solid #d5d5d5;
  overflow:hidden;
  vertical-align: middle;
}
.list .m-2 table tbody td:first-child,
.list .m-2 table tbody td:nth-child(4) {
  text-align: center;
}
.list .m-2 table tbody td:nth-child(5) {
  text-align: right;
}
.list .m-2 table tbody td:nth-child(6) div span,
.list .m-2 table tbody td:nth-child(7) div span,
.list .m-2 table tbody td:nth-child(8) div span,
.list .m-2 table tbody td:nth-child(9) div span {
  float: right;
}
.list .m-2 table tbody td:nth-child(6)  div,
.list .m-2 table tbody td:nth-child(7)  div,
.list .m-2 table tbody td:nth-child(8)  div,
.list .m-2 table tbody td:nth-child(9)  div {
  overflow: hidden;
}

.list .m-2 table tbody td:nth-child(6) div:nth-child(2) span {
  color: #E00;
}
.download-link::after {
  color: #000;
}

.list .m-2 table tbody td:nth-child(3) div:nth-child(3) span {
  color: #C33;
}
.download-link::after {
  color: #000;
}

@media screen and (max-width: 960px) {
  .list > div,
  .list .search {
    flex-direction: column;
  }
  .list #infinite_scroll table thead {
    top: -1px;
  }
  .btn-group-lg > .btn, .btn-lg {
    margin: 0.5rem 0 0 auto;
    padding: .5rem 1rem;
    font-size: 1rem;
    border-radius: .3rem;
  }
}
.section {
  line-height: 2.6;
  font-size: 1.1em;
  font-weight: 550;
  border-bottom: 2px dotted ;
  border-color: #007bff
  }
.modal_table {
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 2px;
  width: 100%;
}

.modal_table th {
  background: #b8daff none repeat scroll 0 0;
  border-bottom: 1px solid #00008b;
  border-top: 1px solid #00008b;
  border-left: 1px solid #CCC;
  line-height: 1.1;
  color: #007bff;
  padding: 2px 14px;
  text-align: center;
}
.modal_table td {
  background: white none repeat scroll 0 0;
  border-bottom: 1px solid #CCC;
  color: #333;
  padding: 2px 4px;
}
 .csv_list {
   border: 1px solid #dee2e6;
   margin: 10px 5px;
 }
 .csv_list table {
   table-layout: auto;
   width: 100%;
 }
 .csv_list table thead th {
   font-weight: normal;
   text-align: center;
   padding: 0 10px;
 }
 .blue {
   color: #007bff;
 }
 .orange {
    color: #ff9800;
 }
 .bold {
   font-weight: bold;
 }
.section-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.section-item {
  margin-right: 20px;
}
.yubi {
  cursor : pointer;
}
input[readonly],textarea[readonly] {
  background-color: #ffffff;
  cursor: default;
}
.title {
  font-size: 1.2rem;
}
</style>
