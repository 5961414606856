<!--

-->
<template>
<Datepicker
    :model-value="modelValue"
    @update:model-value="val => $emit('update:modelValue', val)"
    format="yyyy/MM/dd"
    locale="ja"
    cancelText="Cancel"
    selectText="OK"
    range
    year-first
    :enable-time-picker="false">
  <template #right-sidebar>
    <div class="sidebar text-center">期間選択</div>
    <div class="sidebar hover-red text-center" @click="setThisMonth">
      今月
      <span class="">
        ({{ getDate(thisMonthStart) }} - {{ getDate(thisMonthEnd) }})
      </span>
    </div>
    <div class="sidebar hover-red text-center" @click="setLastMonth">
      先月
      <span class="">
        ({{ getDate(lastMonthStart) }} - {{ getDate(lastMonthEnd) }})
      </span>
    </div>
  </template>
</Datepicker>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker'
export default {
  components: {
    Datepicker
  },
  props: [
    'modelValue'
  ],
  emits: [
    'update:modelValue'
  ],
  computed: {
    thisMonthStart() {
      return (new Date()).setDate(1)
    },
    thisMonthEnd() {
      let date = new Date()
      date.setDate(1)
      date.setMonth(date.getMonth() + 1)
      return date.setDate(0)
    },
    lastMonthStart() {
      let date = new Date()
      date.setMonth(date.getMonth() - 1)
      return date.setDate(1)
    },
    lastMonthEnd() {
      return (new Date()).setDate(0)
    }
  },
  methods: {
    setThisMonth() {
      this.$emit(
        'update:modelValue',
        [this.thisMonthStart, this.thisMonthEnd]
      );
    },
    setLastMonth() {
      this.$emit(
        'update:modelValue',
        [this.lastMonthStart, this.lastMonthEnd]
      );
    },
    getDate(unixtime) {
      let dateTime = new Date(unixtime)
      return dateTime.toLocaleDateString('ja-JP').slice(5)
    },
  }
}
</script>

<style>
.sidebar {
  width: 120px;
}
.hover-red:hover {
   color: red;
}
</style>
