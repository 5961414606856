<template>
  <form v-on:submit.prevent="login">
    <div class="login-form">
      <div>{{ errmsg }}</div>
      <!-- 企業ID -->
      <input type="hidden" placeholder="company id" v-model="user.company">
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">ログインID</label>
        <input type="text" class="form-control" placeholder="xxx@sample.com" v-model="user.userid" autocomplete="username">
      </div>
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">パスワード</label>
        <input type="password" class="form-control" placeholder="" v-model="user.password" autocomplete="current-password">
      </div>
      <button type="bubmit" class="btn btn-primary btn-lg">ログイン</button>
    </div>
  </form>
</template>
<style scope>
.login-form {
  width: 300px;
  margin: 10px auto;
  text-align: center;
  border: solid 1px #d5d5d5;
  border-radius: 2px;
  box-shadow: 2px 4px 10px 0px #d5d5d5;
  padding: 10px;
}

</style>
<script>
export default {
  name: 'LoginView',
  data() {
    return {
      user: {},
      errmsg: ''
    };
  },
  mounted() {
    let url = new URL(location.href)
    let id = url.searchParams.get('id')
    if (id) {
      this.user.company = id
    } else {
      this.errmsg = '企業IDが指定されていません'
    }
  },
  methods: {
    login() {
      if (!this.user.company) {
        return
      }
      this.$crmapi.login(this.user).then((response) => {
        if (response.retval === 0) {
          if (this.$route.query.redirect) {
            // リダイレクト先の指定あり
            this.$router.push(this.$route.query.redirect)
          } else {
            // なければTopへ
            this.$router.push('/scheduleresult')
          }
        }
      })
      .catch((error) => console.error(error))
    }
  }
}
</script>
