import store from '@/store'
import { saveAs } from 'file-saver'

/**
 * CRM操作用APIプラグイン
 */
export default {
  app: null,
  store: null,

  /**
   * app.use() 時に呼ばれる コンストラクタの様なもの
   */
  install(app) {
    // 各コンポーネント内で this.$crmapi で参照できるようにする
    app.config.globalProperties.$crmapi = this
    this.app = app
  },

  // api endpoint
  ENDPOINT: process.env.VUE_APP_API_ORIGIN + "/crm/api.php",
  /**
   * APIを実行
   * @param {object} params: リクエストパラメータのオブジェクト
   *        {bool} setCommonParam: Commonパラメータを付加するか
   *                 (def) true : token, companyid を付加してコールする
   *                       false:  "               を付加しない (ログイン時のAPIコール時)
   * @return {Promise} then : API処理成功 (retval が 0)
   *                   catch:  "     失敗 ( "     が 0 以外)
   */
  callApi(params, setCommonParam = true) {
    if (setCommonParam) {
      params.token = store.state.token
      params.companyid = store.state.company
    }
    return this.app.axios.post(this.ENDPOINT, this.createRequest(params))
      .then((response) => {
        if (response.data.retval === undefined) {
          throw response.data
        } else if (response.data.retval === 0) {
          return response.data
        } else if (response.data.retval === 4 || response.data.retval >= 100000) {
          return response.data
        } else {
          throw response.data
        }
      })
      .catch(function (error) {
        throw error
      })
  },
  /**
   * 渡されたオブジェクトのキーと値のペアで、URLSearchParamsインスタンスを作成する
   * @param {object} params: リクエストパラメータ
   * @return {URLSearchParams}
   */
  createRequest(params) {
    let request = new URLSearchParams()
    for (let item in params) {
      request.append(item, params[item])
    }
    return request
  },
  /**
   * ログイン
   * @param {object} user: 認証情報
   *                 {
   *                   company: ログイン先の企業ID,
   *                   userid: ログインID(メールアドレス),
   *                   password: ログインパスワード
   *                 }
   * @return {Promise} then : ログイン成功
   *                   catch:  "      失敗
   */
  login(user) {
    let params = {
      method: 'login',
      id: user.company,
      login: user.userid,
      password: user.password,
      relogin: false
    }
    return this.callApi(params, false)
      .then((response) => {
        if (response.retval === undefined) {
          throw response
        } else if (response.retval === 0) {
          store.commit('saveToken', response.token)
          store.commit('saveUid', user.userid)
          store.commit('saveCompany', user.company)
          return response
        } else if (response.retval === 1) {
          throw response
        } else {
          throw response
        }
      })
      .catch(function (error) {
        throw error
      })
  },
  /**
   * タイムアウトチェック
   * @return {Promise} then : タイムアウトしていない ログイン維持
   *                   catch:  "          している 要再ログイン
   */
  checkTimeout() {
    let params = {
      method: 'checktimeout',
    }
    return this.callApi(params)
      .then((response) => {
        if (response.retval === undefined) {
          throw response
        } else if (response.retval === 0) {
          return
        } else if (response.retval === 1) {
          throw '一定時間操作が無かったため、タイムアウトしました。再度ログインしてください。'
        } else {
          throw response
        }
      })
      .catch((error) => {
        throw error
      })
  },
  download_csv(params, filename = '', setCommonParam = true) {
    if (setCommonParam) {
      params.token = store.state.token
      params.companyid = store.state.company
    }
    return this.app.axios.post(this.ENDPOINT, this.createRequest(params), { responseType: 'blob' })
       .then((res) => {
         filename = filename || 'download.csv'
         const blob = new Blob([res.data], { type: 'text/csv;charset=utf-16le;' });
         saveAs(blob, filename);
       })
      .catch((error) => {
        console.log(error.messagae);
      });
  },
}
