<template>
  <router-link to="/scheduleresult" v-slot="{ navigate }" custom>
    <div class="tab" @click="navigate">配信予定・結果</div>
  </router-link>
  <div class="tab on">原稿設定<span class="me-2"/><font-awesome-icon icon="fas fa-question-circle" class="fa-lg blue" data-bs-toggle="modal" data-bs-target="#main_help"/></div>
  <div class="wrap">
  <div class="container-fluid">
    <div class="row freverse">
      <div class="col-xxl mb-2" id="articlelist">
        <h3 v-if="barticle" class="title">原稿一覧</h3>
        <h3 v-else class="title">メールテンプレート一覧</h3>
        <div class="list">
          <div v-if="barticle" class="text-end m-2">
            <span class="action-button hover-red" @click="on_mail_template" >テンプレートから選択 <font-awesome-icon icon="fa-solid fa-list" /></span>
          </div>
          <div v-else class="text-end">
            <span class="action-button hover-red" @click="on_article" >作成した原稿から選択 <font-awesome-icon icon="fa-solid fa-list" /></span>
          </div>
          <div class="innerlist" id="infinite_scroll" ref="scroll_area">
            <table class="table table-striped table-hover selectable" id="article_list">
              <thead class="sticky-top bg-white">
                <tr v-if="barticle">
                  <th><span class="artno">原稿No</span>原稿名<br><span class="subject">件名</span></th>
                  <th>更新日時</th>
                  <th><!-- 配信済かどうか--> </th>
                </tr>
                <tr v-else>
                  <th>原稿名<br><span class="subject">件名</span></th>
                </tr>
              </thead>
              <tbody v-if="barticle">
                <tr v-for="article in articles" :key="article.inmailtmplno" @click="this.tr_click($event)" :no="article.inmailtmplno">
                  <td><span class="artno">No.{{ article.inmailtmplno }}</span>{{ article.stnamae }}<br><span class="subject">件名:{{ article.stsubject }}</span></td>
                  <td>{{ article.tikoushin }}</td>
                  <td>{{ this.does_already_sent(article.ststatus) ? '配信済' : '' }}</td>
                </tr>
                <tr v-show = " 0 < this.numall - this.articles.length">
                  <td colspan="3" id="loading">読み込み中...</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="mailtemplate in mailtemplates" :key="mailtemplate.inno" @click="this.tr_click($event)" :no="mailtemplate.inno">
                  <td>{{ mailtemplate.stnamae }}<br><span class="subject">件名:{{ mailtemplate.stsubject }}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div id="articleedit" class="col-xxl mb-2">
        <h3 class="title">原稿編集<span class="me-2"/><font-awesome-icon icon="fas fa-question-circle" class="fa-lg blue" data-bs-toggle="modal" data-bs-target="#article_help"/></h3>
        <div class="form">
          <div class="text-end">
            <button v-if="barticle" id="change" type="button" class="btn btn-outline-primary btn-lg" @click="change">変更</button>
            <button id="add" type="button" class="btn btn-outline-success btn-lg" @click="add">追加</button>
            <button id="reset" type="button" class="btn btn-outline-dark btn-lg" @click="reset">リセット</button>
            <button v-if="barticle" id="clear" type="button" class="btn btn-outline-info btn-lg" @click="clear">クリア</button>
            <button v-if="barticle" id="delete" type="button" class="btn btn-danger btn-lg" @click="delete_article">削除</button>
          </div>
          <div class="input_area">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">原稿名</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="" v-model="article.stnamae" prop="stnamae">
            </div>
            <!--
              <div class="mb-3" style="margin:0">
              <div><label class="form-label">メール種別</label></div>
              <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="mailkind" id="mailkind_text" value="text" v-model="article.mailtype">
              <label class="form-check-label" for="mailkind_text">テキスト</label>
              </div>
              <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="mailkind" id="mailkind_html" value="html" v-model="article.mailtype">
              <label class="form-check-label" for="mailkind_html">HTML</label>
              </div>
              </div>
            -->
            <div class="mb-3">
              <label for="exampleFormControlInput2" class="form-label">件名</label>
              <input type="text" class="form-control" id="exampleFormControlInput2" placeholder="" v-model="article.stsubject" prop="stsubject">
            </div>
            <div class="mb-3 sentence" id="mailbody">
              <label for="exampleFormControlTextarea1" class="form-label">本文</label>
              <textarea v-if="barticle" class="form-control" id="txtMailBody" rows="22" v-model="article.stbunmen" prop="stbunmen" @contextmenu ="onContextMenu($event)">
              </textarea>
              <textarea v-else class="form-control" id="txtMailBody" rows="22" v-model="article.streplaced" prop="stbunmen" @contextmenu ="onContextMenu($event)"> 
              </textarea>
              <context-menu
                v-model:show="show"
                :options="optionsComponent"
                >
                <context-menu-item label="切り取り" @click="cut" :disabled="do_copy"/>
                  <context-menu-item label="コピー" @click="copy" :disabled="do_copy"/>
                    <context-menu-item label="貼り付け" @click="paste" :disabled="do_paste" />
                      <context-menu-group label="クリック測定" @subMenuOpen="sub_open" >
                        <context-menu-item  v-for="url in reverseUrls" :key="url.no" :value="url.no" @click="insert_clickurl(url)">{{url.no}}.{{ url.value }}</context-menu-item>
                      </context-menu-group>
                <context-menu-group label="#変数">
                  <context-menu-item v-for="v in insert_valiables" :key="v.no" :value="v.no" @click="insert_valiable(v)">{{ v.name}}({{v.value}})</context-menu-item>
                </context-menu-group>
                    </context-menu>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput3" class="form-label">配信元名称</label>
              <input type="text" class="form-control" id="exampleFormControlInput3" placeholder="" v-model="article.sthaishinmoto" prop="sthaishinmoto">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput4" class="form-label">メールアドレス</label>
              <input type="email" class="form-control" id="exampleFormControlInput4" placeholder="" v-model="article.stfrom" prop="stfrom">
            </div>
            <!--
              <div class="mb-3">
              <label for="selSignature" class="form-label">署名</label>
              <select class="form-select" id="selSignature" v-model="article.insignatureno"><option value="0">(署名なし)</option><option v-for="sig in signatures" :key="sig.no" :value="sig.no">{{ sig.value }}</option></select>
              </div>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="barticle" class="container-fluid">
    <div class="row">
      <div id="sendtest" class="col-xxl mb-2">
        <h4 class="title">テスト配信<span class="me-2"/><font-awesome-icon icon="fas fa-question-circle" class="fa-lg blue" data-bs-toggle="modal" data-bs-target="#test_help"/></h4>
        <div class="testmail">
          <div class="my-3">
            <div class="ps-2 text-start">
              <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#add_tester_modal" @click="this.open_search_tester()">配信先を追加する</button>
            </div>
          </div>
          <div v-if="this.tester.length==0" class="red ms-2">テスト配信先を追加してください。</div>
          <div v-else>
            <div class="allcheck">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="chkAllTester" @change="select_all_tester($event.target.checked)" checked>
                <label class="form-check-label" for="chkAllTester">すべてチェック</label>
              </div>
            </div>
            <div class="ps-2 text-start" id="tester">
              <table>
                <tr v-for="t in tester" :key="t.inmemberno">
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" :value="t.inmemberno" :id="'chkTester_' + t.inmemberno" @click="switch_all_tester_checked" checked>
                      <label class="form-check-label" :for="'chkTester_' + t.inmemberno" >
                        {{ t.stemail }} ({{ t.stkaisha }} {{ t.stnamae }})
                      </label>
                    </div>
                  </td>
                  <td>
                    <div class="wrapper">
                      <div class="icon ">
                        <span class="tooltip">テスト配信先を削除</span>
                        <span><font-awesome-icon icon="fa-solid fa-trash" class="fa-lg ms-4 clickable" @click="delete_tester(t.inmemberno)"/></span>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <hr>
            <div>
              <button type="button" class="btn btn-primary btn-lg"  @click="test_mail()">テスト配信</button>
            </div>
          </div>
          <div class="mt-3">テスト配信結果</div>
          <div v-if="!does_test_mail()" class="red ms-2">テスト配信がされていません。</div>
          <div v-else>
            <table>
              <tr v-for="t in testresults" :key="t[0]">
                <td>{{ t[1] }}</td>
                <td><div class="addresswindow">{{ t[4] }}</div></td>
                <td>{{ t[2] }}</td>
                <td><div class="wrapper">
                    <div class="icon ">
                      <span class="tooltip">テスト配信結果を削除</span>
                      <span><font-awesome-icon icon="fa-solid fa-trash" class="fa-lg" @click="delete_testresult(t[0])" /></span>
                    </div></div></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div v-if="barticle" class="col-xxl mb-2">
        <h4 class="title">配信設定<span class="me-2"/><font-awesome-icon icon="fas fa-question-circle" class="fa-lg" style="color:#007bff;" data-bs-toggle="modal" data-bs-target="#schedule_help"/></h4>
        <div class="schedule">
          <div>
            <button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#reserve_modal" @click="r_m_reset">配信を予約する</button>
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>状態</th>
                  <th>配信日時</th>
                  <th>区分<br>クエリ―名/ファイル名<br>配信基準</th>
                  <th>配信(予定)数</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="text-end">
                <tr v-for="s in schedules" :key="s.inhaisouno" :class="s.bsent && 'sent'">
                  <td>{{ s.bsent == 't' ? '配信済' : '未配信' }}</td>
                  <td>{{ s.tihaisou }}</td>
                  <td>{{ s.sttype == 'query' ? 'クエリー' : (s.sttype == 'csv' ? 'CSV' : '全') }}配信<br>{{ s.listname }}<br>{{ s.options_criteria }}</td>
                  <template v-if="s.bsent == 't'">
                    <td><div class="wrapper"><div class="icon"><span class="tooltip">配信先リストをダウンロード</span><span class="download-link fa-lg" @click="download(s)">{{ s.num }}</span></div>
                      </div></td>
                      <td><div class="wrapper"><div class="icon"><span class="tooltip">配信設定のプレビューを見る</span><span><font-awesome-icon icon="fa-solid fa-circle-info" @click="setting_modal_display(s)" data-bs-toggle="modal" data-bs-target="#reserve_modal" class="fa-lg"/></span></div></div></td>
                      <td></td>
                  </template>
                  <template v-else>
                    <td><div class="wrapper"><div class="icon"><span class="tooltip">配信先リストをダウンロード</span><span class="download-link fa-lg" @click="download(s)">{{ s.num }}</span></div>
                      </div></td>
                      <td><div class="wrapper"><div class="icon"><span class="tooltip">配信スケジュールを変更</span><span><font-awesome-icon icon="fa-solid fa-pen-to-square" data-bs-toggle="modal" data-bs-target="#reserve_modal" @click="change_okbutton(s)" class="fa-lg" /></span></div></div></td>
                      <td><div class="wrapper"><div class="icon"><span class="tooltip">配信スケジュールを削除</span><span><font-awesome-icon icon="fa-solid fa-trash" @click="delete_schedule(s.inhaisouno)"  class="fa-lg"/></span></div></div></td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="modal fade" id="reserve_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">配信予約</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="r_m_reset"></button>
        </div>
        <div class="modal-body">
          <div>
            ■配信リストを選択
            <div class="form-check">
              <input class="form-check-input" type="radio" name="mailType" id="mailtype_all" value="all" v-model="mailkind" @change="switch_select($event.currentTarget.value)">
              <label class="form-check-label" for="mailtype_all">全員配信(登録されている全員に配信)</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="mailType" id="mailtype_query" value="query" v-model="mailkind" @change="switch_select($event.currentTarget.value)">
              <label class="form-check-label" for="mailtype_query">クエリー配信(指定したクエリーの対象に配信)</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="mailType" id="mailtype_csv" value="csv" v-model="mailkind" @change="switch_select($event.currentTarget.value)">
              <label class="form-check-label" for="mailtype_csv">CSV配信(アップロードしたリスト内の対象に配信)</label>
            </div>
            <div class="" v-show="query_area">
              <div class="">
                <label class="" for="query_kind">種別</label>
                <div class="">
                  <select class="form-select" id="query_kind" v-model="querykind" @change="get_queries">
                    <option value="customer">顧客情報</option><option value="follow">フォロー情報</option><option value="appointment">外出情報</option><option value="project">案件情報</option><option value="estimate">見積書</option><option value="invoice">請求書</option><option value="enqueteview">アンケート閲覧</option><option value="enqueteanswer">アンケート回答</option><option value="urlview">クリック測定</option><option value="sentmail">メール配信</option><option value="confirmmail">ステップ配信予定</option><option value="event">イベント情報</option><option value="tracking">追跡</option>
                  </select>
                </div>
              </div>
              <div class="">
                <div v-show="show_query_name">
                  <label class="" for="query">クエリ―名</label>
                  <div>
                    <select id="query" class="form-select" v-model="querynumber" @change="get_query_info">
                      <option v-for="q in queries" :key="q.inno" :value="q.inno">{{ q.stnamae }}</option>
                    </select>
                  </div>
                  <div  class="m-1">
                    <table class="border border-1">
                      <!--<tr v-for="(cond, label) in query_info.cond" :key="label">-->
                      <tr v-for="(cond, label) in get_query_info_cond()" :key="label">
                        <th>{{ label }}</th>
                        <td class="text-start">{{ cond }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div v-show="noquery">クエリーが存在しません</div>
              </div>
            </div>
            <div class="" v-show="csv_area">
              <div class="input-group">
                <input type="file" class="form-control" id="inputGroupFile04" accept=".csv"   aria-describedby="inputGroupFileAddon04" aria-label="Upload">
                <button class="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04" @click="upload_file" >アップロード</button>
              </div>
              <div v-show="csv_list">
                <div class="search-tester-detail">
                  <table>
                    <thead class="sticky-top bg-white">
                      <tr>
                        <th class="text-center">ファイル名</th>
                        <th class="text-center">登録日時</th>
                        <th class="text-center">配信件数</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="f in files" :key="f.filename">
                        <td>{{ f.filename }}</td>
                        <td>{{ f.date }}</td>
                        <td>{{ f.result.num}}件</td>
                      </tr>
                      <tr v-for = "f in display_files" :key="f.name">
                        <td>{{ f.name }}</td>
                        <td>{{ f.date }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class= text-end>
                  <button class="btn btn-secondary" id="delete_file" @click="delete_file">リストを全削除</button>
                </div>
              </div>
            </div>

            <!--
              <div class="form-check">
              <input class="form-check-input" type="radio" name="mailType" id="mailtype_csv" value="all" v-model="mailkind">
              <label class="form-check-label" for="mailtype_all">全員配信(登録されている全員に配信)</label>
              </div>
            -->
          </div>
          <div>
            ■配信基準設定
            <div>
              同一メールアドレスへの配信方法
              <div class="form-check ms-1">
                <input class="form-check-input" type="radio" name="criteria_customer" id="criteria_all" value="all" checked v-model="criteria_email">
                <label class="form-check-label" for="criteria_all">顧客No基準</label>
              </div>
              <div class="form-check ms-1">
                <input class="form-check-input" type="radio" name="criteria_customer" id="criteria_one" value="one" v-model="criteria_email">
                <label class="form-check-label" for="criteria_one">メールアドレス基準</label>
              </div>
            </div>
            <div>
              追加データに関する配信方法
              <div class="form-check ms-1">
                <input class="form-check-input" type="radio" name="criteria_target" id="criteria_include" value="include" checked v-model="criteria_onthefly">
                <label class="form-check-label" for="criteria_include">配信時基準</label>
              </div>
              <div class="form-check ms-1">
                <input class="form-check-input" type="radio" name="criteria_target" id="criteria_exclude" value="exclude" v-model="criteria_onthefly">
                <label class="form-check-label" for="criteria_exclude">登録時基準</label>
              </div>
            </div>
          </div>
          <div>
            ■配信日時
            <Datepicker v-model="delivery_datetime" :start-time="startTime" time-picker-inline format="yyyy/MM/dd HH:mm" locale="ja"  cancelText="Cancel" selectText="OK" minutes-increment="5" :disabled="this.readonly" @open="set_startTime"></Datepicker>
          </div>
        </div>
        <div v-if="change_sch" class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="r_m_reset">キャンセル</button>
          <!--
            <button type="button" class="btn btn-primary" data-bs-target="#info_modal" data-bs-toggle="modal" data-bs-dismiss="modal" @click="save_schedule">確定</button>
          -->
          <button type="button" class="btn btn-primary" @click="save_schedule">変更</button>
        </div>
        <div v-else class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="r_m_reset">キャンセル</button>
          <!--
            <button type="button" class="btn btn-primary" data-bs-target="#info_modal" data-bs-toggle="modal" data-bs-dismiss="modal" @click="save_schedule">確定</button>
          -->
          <button type="button" class="btn btn-primary" @click="save_schedule">確定</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="add_tester_modal"  tabindex="-1" aria-labelledby="addTesterModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addTesterModalLabel">テスト配信リスト登録</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="at_m_reset"></button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-inline">
              <form @submit.prevent="this.search_tester">
                <label for="searchTesterCompany">会社名</label>
                <input type="text" class="form-control w-25 m-2" id="searchTesterCompany">
                <label for="searchTesterCustomer">顧客名</label>
                <input type="text" class="form-control w-25 m-2" id="searchTesterCustomer">
                <button class="btn btn-outline-primary" type="submit">検索</button>
              </form>
            </div>
          </div>
          <div class="search-tester-list">
            <table id="search-tester-list" class="table table-striped table-hover selectable">
              <colgroup>
                <col span="1" style="width: 45%;">
                <col span="1" style="width: 25%;">
                <col span="1" style="max-width: 30%;">
              </colgroup>
              <thead class="sticky-top bg-white">
                <tr>
                  <th class="text-justify">会社名</th>
                  <th class="text-justify">顧客名</th>
                  <th class="text-start">E-mail</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(tester, index) in searchtesters" :key="tester.inmemberno" :index="index" @click="this.click_search_tester($event)">
                  <td>{{ tester.stkaisha }}</td>
                  <td>{{ tester.stnamae }}</td>
                  <td>{{ tester.stemail }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="at_m_reset">キャンセル</button>
            <button :disabled="this.selectsearchtester === null" type="button" class="btn btn-primary"  @click="this.add_tester_submit">テスト配信先に追加</button>
            <!--<button :disabled="hasSelectTester" type="button" class="btn btn-primary" data-bs-dismiss="modal">テスト配信先に追加</button>-->
          </div>
          <div class="search-tester-detail">
            <table>
              <tbody>
                <tr>
                  <th>会社名</th>
                  <td>{{ get_select_search_tester('stkaisha') }}</td>
                </tr>
                <tr>
                  <th>部署名</th>
                  <td>{{ get_select_search_tester('stbusho') }}</td>
                </tr>
                <tr>
                  <th>顧客名</th>
                  <td>{{ get_select_search_tester('stnamae') }}</td>
                </tr>
                <tr>
                  <th>E-mail</th>
                  <td>{{ get_select_search_tester('stemail') }}</td>
                </tr>
                <tr>
                  <th style="vertical-align: top;">備考</th>
                  <td><pre style="white-space: break-spaces;">{{ get_select_search_tester('txbikou') }}</pre></td>
                </tr>
              </tbody></table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade help" id="main_help" tabindex="-1" aria-labelledby="main_help" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title bold" id="main_help">メール配信設定画面<span class="me-2"/> ヘルプページ</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <span class="section">全般説明</span>
          <p>この画面ではメール配信の設定ができます。<br>
          メール配信設定とは、メールを作成し、そのメールを指定した宛先に指定した日時で配信する機能です。<br>
          定期的にメール配信を行うことで、見込み客をふるいにかけ、優先順位をつけることができます。また、自社の刷り込み効果も期待できます。</p>
          <p>メール配信は、下記の流れで設定します。</p>
          <ol>
            <li>これから配信するメールの原稿を登録する</li>
            <li>登録したメールの原稿にテスト配信をする</li>
            <li>メール配信先と日時を設定し予約をする</li>
          </ol>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade help" id="article_help" tabindex="-1" aria-labelledby="article_help" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title bold" id="article_help">原稿編集<span class="me-2"/>ヘルプページ</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <span class="section">目次</span>
          <div class="section-box">
            <div class="section_item"><a href="#mail_explain">全般説明</a></div>
            <div class="section_item"><a href="#mail_new">新しく原稿を編集し登録する</a></div>
            <div class="section_item"><a href="#mail_cu">クリック測定URLの挿入</a></div>
            <div class="section_item"><a href="#mail_v">#変数の挿入</a></div>
            <div class="section_item"><a href="#mail_edit">登録した原稿/テンプレートから原稿を編集する</a></div>
          </div>
          <hr>
          <span class="section" id="mail_explain">全般説明</span>
          <p>このエリアでは、配信するメールの原稿を編集・登録します。<br>メールの原稿を登録するには、<a href="#mail_new">新しく原稿を編集する方法</a>と、<a href="#mail_edit">登録した原稿/メールテンプレートの続きから編集する方法</a>の2種類があります。</p>
          <hr>
          <span class="section" id="mail_new">新しく原稿を編集し登録する</span>
          <p>左側の「原稿編集」のエリアで以下の5つの項目を入力し、原稿を編集します。</p>
          <table class="modal_table">
            <thead>
              <tr>
                <th>項目</th><th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>原稿名</td><td>メール配信システムに登録する原稿名。配信先には表示されない</td></tr>
              <tr><td>件名</td><td>配信するメールの件名</td></tr>
              <tr><td>本文</td><td>配信するメールの本文。<a href="mail_cu">クリック測定URL</a>や<a href="mail_v">#変数</a>の挿入が可能</td></tr>
              <tr><td>配信元名称</td><td>メールの配信元として配信先に表示される</td></tr>
              <tr><td>配信アドレス</td><td>メールの配信元アドレスとして配信先に表示される</td></tr>
            </tbody>
          </table>
          <br>
          <p>すべての項目を入力し、<button type="button" class="btn btn-outline-success btn-sm" disabled>追加</button>
          をクリックすれば、原稿が登録され、原稿一覧に表示されます。</p>
          <hr>
          <span class="section" id="mail_cu">クリック測定URLの挿入</span>
          <p>メール本文にクリック測定のURLを挿入することができます。<br>
          本文のエリア内で右クリックを行うと、メニューが表示されます。<br>
          「クリック測定」にカーソルを移動させると、登録したURLのリストが表示されます。<br>
          リストから挿入したいURLを選択し、クリックすればメール本文にURLが挿入されます。</p>
          <hr>
          <span class="section" id="mail_v">#変数の挿入</span>
          <p>メール本文に変数を挿入することができます。<br>
          変数を利用すると、メール本文でその変数に対応した入力内容が宛先ごとに表示されます。
          本文のエリア内で右クリックを行うと、メニューが表示されます。<br>
          「#変数」にカーソルを移動させると、変数のリストが表示されます。<br>
          <table class="modal_table">
            <thead>
              <tr>
                <th>変数</th><th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>#KAISHA</td><td>会社名</td></tr>
              <tr><td>#BUSHO</td><td>部署名</td></tr>
              <tr><td>#NAMAE</td><td>氏名</td></tr>
            </tbody>
          </table>
          <br>
          リストから挿入したいURLを選択し、クリックすればメール本文に変数が挿入されます</p>
          <hr>
          <span class="section" id="mail_edit">登録した原稿/テンプレートから原稿を編集する</span>
          <p>右側の「原稿一覧」のエリアから登録済みの原稿を選んで続きから編集することができます。<br>
          登録済みの原稿には、「作成した原稿」と「テンプレート」との2種類があり、<span class="gray">テンプレートから選択<font-awesome-icon icon="fa-solid fa-list" /></span><span class="me-2"/><span class="me-2"/><span class="gray">作成した原稿から選択 <font-awesome-icon icon="fa-solid fa-list" /></span><span class="me-2"/>をクリックすることでそれぞれの一覧に切り替えることができます。<br>
              それぞれのボタンから原稿の変更・登録・削除ができます。</p>
          <button type="button" class="btn btn-outline-primary btn-sm" disabled>変更</button>
          <p>編集した原稿の内容を上書き保存します。</p>
          <button type="button" class="btn btn-outline-success btn-sm" disabled>追加</button>
          <p>編集した原稿を保存し、一覧に新しく追加します。 <button type="button" class="btn btn-outline-primary btn-sm" disabled>変更</button><span class="me-2"/>
          とは違い、元の原稿も一覧に残ります。</p>
          <button type="button" class="btn btn-outline-dark btn-sm" disabled>リセット</button>
          <p>新たに編集した内容がリセットされ元通りになります。</p>
          <button type="button" class="btn btn-outline-info btn-sm" disabled>クリア</button>
          <p>すべての項目の入力内容が消去されます。</p>
          <button type="button" class="btn btn-danger btn-sm" disabled>削除</button>
          <p>原稿をシステムから削除します。 </p>     
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade help" id="test_help" tabindex="-1" aria-labelledby="test_help" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title bold" id="test_help">テスト配信<span class="me-2"/>ヘルプページ</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <span class="section">目次</span>
          <div class="section-box">
            <div class="section_item"><a href="#test_explain">全般説明</a></div>
            <div class="section_item"><a href="#test_list">テスト配信先を追加・削除する</a></div>
            <div class="section_item"><a href="#test_mail">テスト配信をする</a></div>
            <div class="section_item"><a href="#test_result">テスト配信後の確認</a></div>
          </div>
          <hr>
          <span class="section" id="test_explain">全般説明</span>
          <p>登録した原稿でメール配信を行う前に、メールが正常に配信・表示されるかの確認をするためのテスト配信をこのエリアで行います。<br>
          テスト配信の流れとしては、テスト配信を行いたい原稿を選択し、</p>
          <ol>
            <li><a href="#test_list">テスト配信先を登録</a>して</li>
            <li><a href="#test_mail">テスト配信</a>を行い、</li>
            <li><a href="#test_result">配信結果を確認</a>します。</li>
          </ol>
          <hr>
          <span class="section" id="test_list">テスト配信先を追加・削除する</span>
          <p><button type="button" class="btn btn-primary btn-sm" disabled>配信先を追加する</button><span class="me-2"/>をクリックすると検索画面が出てきます。<br>会社名や顧客名で絞込み検索をし、検索結果からテストメールを送りたいアドレスをクリックで選択し、<button type="button" class="btn btn-primary btn-sm" disabled>テスト配信先に追加</button><span class="me-2"/>をクリックすると、配信先のリストが更新されます。<br></p><p></p>
          <p> テスト配信先からアドレスを削除したい場合は、<font-awesome-icon icon="fa-solid fa-trash" class="fa-1x"/><span class="me-2"/>をクリックします。<br>
            テスト配信先に新しいアドレスを追加したい場合は、<font-awesome-icon icon="fa-solid fa-user-plus" class="fa-1x"/><span class="me-2"/>をクリックすると、上記と同じ操作で追加できます。</p>
          <hr>
          <span class="section" id="test_mail">テスト配信をする</span>
          <p>テストメールは、テスト配信先リストの中から <input class="form-check-input" type="checkbox" checked disabled>がついているアドレスにだけ配信されます。テストメールを送りたいアドレスに<input class="form-check-input" type="checkbox"  checked disabled>をつけ、それ以外は<input class="form-check-input" type="checkbox" checked disabled>を外します。<br></p><p></p>
          <p> <button type="button" class="btn btn-primary btn-sm" disabled>テスト配信</button><span class="me-2"/>をクリックし、テスト配信を行います。<br>
          配信が完了すると、画面下のテスト配信結果が更新されます。<br>テスト配信結果を削除したい場合は、<font-awesome-icon icon="fa-solid fa-trash" class="fa-1x"/><span class="me-2"/>をクリックします。
          </p>
          <hr>
          <span class="section" id="test_result">テスト配信後の確認</span>
          <p>テスト配信ボタンを行ったあと、以下のポイントを確認し問題があればメール原稿を修正します。<br></p><p></p>
          <ol>
            <li>正常にメールが配信できたか？<br>→テスト配信結果をチェック</li>
            <li>テスト配信先に登録したメールアドレスに配信したメールが届いているか？<br>→テスト配信先のメールボックスをチェック</li>
            <li>届いたメールの文面やリンク、変数やクリック測定の動作に問題がないか？<br>→届いたメール本文をチェック</li>
          </ol>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade help" id="schedule_help" tabindex="-1" aria-labelledby="schedule_help" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title bold" id="schedule_help"> 配信設定<span class="me-2"/> ヘルプページ</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <span class="section">目次</span>
          <div class="section-box">
            <div class="section_item"><a href="#schedule_explain">全般説明</a></div>
            <div class="section_item"><a href="#schedule_list">配信リストを選択</a></div>
            <div class="section_item"><a href="#schedule_adress">同一メールアドレスへの配信方法</a></div>
            <div class="section_item"><a href="#schedule_data">追加データに関する配信方法</a></div>
            <div class="section_item"><a href="#schedule_datetime">配信日時</a></div>
            <div class="section_item"><a href="#schedule_edit">未配信スケジュールの確認・変更・削除</a></div>
            <div class="section_item"><a href="#schedule_preview">配信済みスケジュールの確認</a></div>
          </div>
          <hr>
          <span class="section" id="schedule_explain">全般説明</span>
          <p>このエリアではメール配信の予約を行います。</p><p></p><p><button type="button" class="btn btn-primary btn-sm" disabled>配信を予約する</button><span class="me-2"/>をクリックして開いた画面で、各項目（<a href="#schedule_list">配信リストを選択</a>、<a href="#schedule_adress">同一メールアドレスへの配信方法</a>、<a href="#schedule_data">追加データに関する配信方法</a>、<a href="#schedule_datetime">配信日時</a>）を設定し、<button type="button" class="btn btn-primary btn-sm" disabled>確定</button><span class="me-2"/>をクリックすると配信が予約されます。</p><p></p>
          <p> また、画面下にメールの配信スケジュールが表示され、<a href="#schedule_edit">未配信スケジュールの確認・変更・削除</a>、<a href="#schedule_preview">配信済みスケジュールの確認</a>が行えます。</p>

          <hr>
          <span class="section" id="schedule_list">配信リストを選択</span>
          <p>メール配信をする宛先リストの設定です。</p>
          <table class="modal_table">
            <thead>
              <tr>
                <th>選択肢</th><th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>全員配信</td><td>データベースに登録されている全顧客に配信。総合的なメルマガ配信などで使用する</td></tr>
              <tr><td>クエリー配信</td><td>キーワードをもとにクエリー登録された顧客にのみ配信。あらかじめクエリーの登録が必要。展示会のお礼メールなどで使用</td></tr>
              <tr><td>CSV配信</td><td> データベースからダウンロードした顧客データのCSVファイルを使って配信。CSVファイルはExcelで加工することができ、1件ずつ配信先を選択編集できる</td></tr>
            </tbody>
          </table>
          <hr>
          <span class="section" id="schedule_adress">同一メールアドレスへの配信方法</span>
          <p>配信リストに同一のメールアドレス登録があった場合の配信方法の設定です。<br>通常は、初期設定の「顧客No」基準のままで構いません。</p>
          <table class="modal_table">
            <thead>
              <tr>
                <th>選択肢</th><th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>「顧客No」基準</td><td>同一のメールアドレスでも顧客Noが違えば全てに配信する(★初期設定)</td></tr>
              <tr><td>「メールアドレスNo」基準</td><td>同一のメールアドレスなら配信時に一番新しい登録(更新日時)にのみ配信する</td></tr>
            </tbody>
          </table>

          <hr>
          <span class="section" id="schedule_data">追加データに関する配信方法</span>
          <p>配信設定後にデータベースへ追加されたデータがあった場合の配信方法の設定です。<br>通常は、初期設定の「配信時」基準のままで構いません。</p>
          <table class="modal_table">
            <thead>
              <tr>
                <th>選択肢</th><th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>「配信時」基準</td><td>配信設定後にデータベースへ追加されたデータも配信対象とする(★初期設定)</td></tr>
              <tr><td>「登録時」基準</td><td>配信設定後にデータベースへ追加されたデータは配信対象としない</td></tr>
            </tbody>
          </table>
          <hr>
          <span class="section" id="schedule_datetime">配信日時</span>
          <p>メールを配信する日時の設定です。</p>
          <p>入力欄の、<font-awesome-icon icon="fa-solid fa-calendar" class="fa-lg white"/><span class="me-2"/>をクリックすると、カレンダーと時計が開きます。配信したい日時を選び、 <button type="button" class="btn btn-primary btn-sm" disabled>OK</button><span class="me-2"/>をクリックすると配信時刻が設定できます。<br>配信時刻は、現在時刻から5分後以降の日時しか設定することができません。</p>
          <hr>
          <span class="section" id="schedule_edit">未配信スケジュールの変更・削除</span>
          <p> エリア下部に、配信スケジュールがリストとして表示されます。<br>まだメールの配信がされていないスケジュールは、<font-awesome-icon icon="fa-solid fa-square" class="fa-lg pink"/><span class="me-2"/>の列で表示されます。</p><p></p>
            <p> <span class="download-link fa-1x"/><span class="me-2"/>をクリックすると、配信予定先のリストがCSVファイルとしてダウンロードできます。<br>
              設定したスケジュールを変更したい場合は、<font-awesome-icon icon="fa-solid fa-pen-to-square" class="fa-1x"/><span class="me-2"/>をクリックします。配信の予約と同じ手順で再予約できます。<br>
                設定したスケジュールを削除したい場合は、<font-awesome-icon icon="fa-solid fa-trash" class="fa-1x"/><span class="me-2"/>をクリックします。</p>
            <hr>
            <span class="section" id="schedule_preview">配信済結果の確認</span>
            <p> すでにメールの配信がされたスケジュールは、配信結果として<span class="me-2"/><font-awesome-icon icon="fa-solid fa-square" class="fa-lg green"/><span class="me-2"/>の列で表示され、設定の詳細を確認できます。</p><p></p>
              <p> <span class="download-link fa-1x"/><span class="me-2"/>をクリックすると、配信予定先のリストがCSVファイルとしてダウンロードできます。<br>
                配信設定のプレビューを見たい場合は、<font-awesome-icon icon="fa-solid fa-circle-info" class="fa-1x"/><span class="me-2"/>をクリックします。</p>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js'
import { ContextMenu, ContextMenuGroup, ContextMenuItem } from '@imengyu/vue3-context-menu';
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import store from '@/store'
export default {
  name: 'EditView',
  data() {
    return {
      barticle: true,
      articles: [],
      article: {},
      article_org: {},
      articleno: null,
      update_article: false,
      from: 0,
      num: 20,
      numall: 0,
      mailtemplates: [],
      mailtemplate: {},
      mailtemplateno: null,
      clickurls: [],
      do_copy: true,
      do_paste: true,
      selText: '',
      st: '',
      insert_valiables:[ {name:"会社名",value:"#KAISHA#"}, {name:"部署名",value:"#BUSHO#"}, {name:"名前",value:"#NAMAE#"}],
      signatures: [],
      tester: [],
      mailkind: '',
      querykind: 'customer',
      querynumber: '',
      queries: [],
      query_info: {},
      reserve_modal: null,
      add_tester_modal: null,
      info_modal: null,
      r_m: null,
      at_m:null,
      unsave: false,
      delivery_datetime: null,
      startTime: {},
      criteria_onthefly: 'include',
      criteria_email: 'all',
      schedules: [],
      skoushin: {},
      modal_message: '',
      modal_callback: '',
      testresults: [],
      // テスト配信先検索
      searchtesters: [],
      selectsearchtester: null,
      change_sch: false,
      readonly: false,
      query_area: false,
      noquery: false,
      show_query_name: true,
      csv_area: false,
      csv_list: false,
      files: [],
      display_files: [],
      show: false,
      optionsComponent: {
        closeWhenScroll: false,
        customClass: "submenu",
        zIndex: 2000,
        minWidth: 230,
        x: 500,
        y: 200,
      },
    }
  },
  components: {
    ContextMenu,
    ContextMenuGroup,
    ContextMenuItem,
  },
  mounted() {
    this.get_articles(false, false).then(() => {
      this.disabled_buttons()
    })
    this.get_userinfo()
    this.get_clickurls()
    this.get_signatures()
    this.set_check_update_event()
    let url = new URL(location.href)
    if (url.searchParams.get('no')) {
      this.articleno = url.searchParams.get('no')
      this.draw_article()
      const posedit = document.getElementById('articleedit');
      const postop = posedit.getBoundingClientRect().top;
      const newY = window.scrollY + postop;
      window.scrollTo(0, newY);
    }
    this.set_tester()
    let element = document.getElementById('infinite_scroll')
    element.addEventListener('scroll', this.on_scroll)
    element.scroll({ top:0, behavior: 'smooth' });
    this.reserve_modal = document.getElementById('reserve_modal')
    this.add_tester_modal = document.getElementById('add_tester_modal')
    this.info_modal = document.getElementById('info_modal')
    this.r_m = new bootstrap.Modal(this.reserve_modal, {})
    this.at_m = new bootstrap.Modal(this.add_tester_modal, {})
  },
  beforeRouteLeave(to, form, next){
    if (this.unsave) {
      let answer = window.confirm('保存していない変更は失われます。よろしいですか？')
      if(answer){
        next()
      } else {
          next(false)
      }
      this.unsave = false
    } else {
        next();
    }
  },
  methods: {
    on_schedule_result() {
       window.location.href = '/scheduleresult'
    },
    on_scroll () {
      let client_height = this.$refs.scroll_area.clientHeight
      let scroll_height = this.$refs.scroll_area.scrollHeight
      let scroll_top = this.$refs.scroll_area.scrollTop
      if (scroll_height - (client_height + scroll_top) < 20) {
        // 読み込み分まで最後までスクロールされた場合
        if (0 < this.numall - this.articles.length) {
          // 全部読み込んでいない場合
          //  if (0 <= (this.articles.length - this.num)) {
          if (this.update_article) { return; }
          this.from += 20
          this.update_article = true;
          this.get_articles(true, false)
          // }
        }
      }
    },
    copy(){
      if (window.getSelection) { 
        this.selText = window.getSelection()
        this.st = this.selText.toString()
      }
      if (this.st.length > 0) {
        navigator.clipboard.writeText(this.st)
        this.do_paste = false
      }
    },
    paste() { 
      navigator.clipboard.readText().then((text) => {
        this.insert_string(text)
      }) 
    },
    cut(){
      this.copy()
      this.delete_text()
    },
    delete_text() {
      if(this.st.length > 0) {
        var tarea = document.getElementById('txtMailBody')
        tarea.focus()
        var strings = tarea.value
        var start = tarea.selectionStart
        var end = tarea.selectionEnd
        tarea.value =  strings.substr(0, start) + strings.substr(end)
        if (this.barticle) {
          this.article.stbunmen = tarea.value
        } else {
          this.article.streplaced = tarea.value
        }
        this.selText.deleteFromDocument()
        tarea.setSelectionRange(start, start)
      }
    },
    onContextMenu(e) {
      e.preventDefault();
      //Set the mouse position
      this.optionsComponent.x = e.x;
      this.optionsComponent.y = e.y;
      //Show menu
      this.show = true;
      let  d= window.getSelection()
      let ds= d.toString()
      if (ds.length > 0) {
        this.do_copy = false
      } else {
        this.do_copy= true
      }
    },
    sub_open() {
      setTimeout(() => this.add_scroll(), 100)
    },
    add_scroll() {
      var s = document.querySelector(".submenu:not(.mx-menu-host)")
      if(s){
        s.style.overflowY="scroll"
      }
      var m = document.querySelector(".mx-context-no-clickable.down")
      if(m) {
        m.style.display="none"
      }
    },
    alertContextMenuItemClicked(name) {
      alert('You clicked ' + name + ' !');
    },
    on_mail_template() {
      if (this.unsave) {
        if (!confirm('保存していない変更は失われます。よろしいですか？')) {
            return
        }
        this.unsave = false
      }
      this.barticle = false;
      this.get_mailtemplate().then(() => {
        this.disabled_buttons()
      })
      this.article = {}
      this.article_org = {}
    },
    on_article(bselected = false) {
      this.barticle = true;
      this.get_articles(false, true).then(() => {
        if (!bselected) {
          this.disabled_buttons()
        }
      })
      this.article = {}
      this.article_org = {}
    },
    get_schedule() {
      this.$crmapi.callApi(
        {
          method: 'search',
          kind: 'mailschedule',
          mode: 'search',
          cond: JSON.stringify({ inmailtmplno: this.articleno, order: 'desc' }),
          catalog: 'mail',
          format: 'json' 
        }
      ).then((response) => {
        this.schedules = response.result
      })
    },
    format_datetime(d) {
      if (!d) {
        return ''
      }
      return d.getFullYear() + '-' + ("00" + (d.getMonth()+1)).slice(-2) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + ("00" + d.getSeconds()).slice(-2)
    },
    test_mail() {
      if (!confirm('選択した配信先にテストメールを送信します。よろしいですか？')) {
        return
      }
      var chktesters  = document.querySelectorAll('input[id^="chkTester"]:checked');
      let mtesters = [];
      for(let tester of chktesters){
        mtesters.push(tester.value);
      }
      this.$crmapi.callApi(
        {
          method: 'testmail',
          no: this.articleno,
          recipient: JSON.stringify(mtesters),
          format: 'json'
        }
      ).then(() => {
        this.get_test_results();
      })
    },
    does_test_mail() {
      return !!this.testresults.length
    },
    get_test_results() {
      this.$crmapi.callApi(
        {
          method: 'list',
          kind: 'mailtest',
          no: this.articleno,
        }
      ).then((response) => {
        this.testresults = response.result
      })
    },
    /**
    * すべてのテスト配信先のチェックをOn/Offする
    */
    select_all_tester(checked) {
      for (let input of document.querySelectorAll("input[id^='chkTester']")) {
        input.checked = checked
      }
    },
    /**
    * テスト配信先の選択状況に応じて、「すべてチェック」の状態を切り替える
    */
    switch_all_tester_checked() {
      let bit = 0 // 1: すべて選択されている
      // 2: すべて未選択
      // 3: 選択・未選択混在
      let chkboxes = document.querySelectorAll("input[id^='chkTester']");
      for (let chkbox of chkboxes){
        bit |= chkbox.checked ? 1 : 2
      }
      let allchkbox = document.getElementById("chkAllTester");
      allchkbox.indeterminate = false;
      if (bit == 1) {
        allchkbox.checked = true;
      } else if (bit == 2) {
        allchkbox.checked = false;
      } else {
        allchkbox.indeterminate = true;
      }
    },
    delete_testresult(trno) {if (!confirm('テスト配信結果を削除します。よろしいですか？')) {
      return
    }
      this.$crmapi.callApi(
        {
          method: 'delete',
          kind: 'testmail',
          no: trno,
          format: 'json'
        }
      ).then(() => {
        this.$crmapi.callApi(
          {
            method: 'list',
            kind: 'mailtest',
            no: this.articleno,
            format: 'json'
          }
        ).then((response) => {
          this.testresults = response.result
        })
      })
    },
    set_tester() {
      this.$crmapi.callApi(
        {
          method: 'loadsetting',
          kind: 'mailtester',
        }
      ).then((response) => {
        this.tester = response.result
      })
    },
    insert_clickurl(url) {
      let insert_url = this.info.stbaseurl + '/l.html#QURL' + url.no + '#' + "\n"
      this.insert_string(insert_url)
    },
    insert_string (insert) {
      var tarea = document.getElementById('txtMailBody')
      tarea.focus()
      this.selText = window.getSelection()
      this.st = this.selText.toString()
      if (this.st.length > 0) {
        this.delete_text()
      }
      var strings = tarea.value
      var at = tarea.selectionStart//カーソルがある部分
      var tmp = strings.substr(0, at)//一番最初からカーソルがある部分までの文字列
      tarea.value = tmp + insert + strings.substr(at, strings.length)//カーソルがある部分から文字列の最後までの文字列
      if (this.barticle) {
        this.article.stbunmen = tarea.value
      } else {
        this.article.streplaced = tarea.value
      }
      var cursor = Math.floor(insert.length) + at
      tarea.setSelectionRange(cursor, cursor)
    },
    insert_valiable(v) {
      let insert_valiable = v.value
      this.insert_string(insert_valiable)
    },
    set_check_update_event() {
      let inputs = document.querySelectorAll('input[prop], textarea[prop], select[prop]')
      for (let input of inputs) {
        input.addEventListener('change', (e) => {
          this.set_changed(e.currentTarget)
        }, true)
      }
    },
    set_changed(elem) {
      let prop = elem.getAttribute('prop')
      if (this.article[prop] == this.article_org[prop]) {
        elem.className = elem.className.replace(/changed/g, '')
        this.unsave = false
      } else if (!~elem.className.indexOf('changed')) {
        elem.className += ' changed'
        this.unsave = true
      }
    },
    tr_click(event) {
      if (this.unsave) {
        if (!confirm('保存していない変更は失われます。よろしいですか？')) {
            return
        }
        this.unsave = false
      }
      let tbody = event.currentTarget.parentNode
      for (let tr of tbody.children) {
        tr.className = tr.className.replace(/selected/g, '')
      }
      event.currentTarget.className += ' selected'
      this.articleno = event.currentTarget.getAttribute('no')
      if (this.barticle) {
        this.draw_article()
        this.get_schedule()
        this.get_test_results()
      } else {
        this.draw_mailtemplate()
      }
      this.switch_button_stat('afterselect');
      const posedit = document.getElementById('articleedit');
      const postop = posedit.getBoundingClientRect().top;
      const newY = window.scrollY + postop;
      window.scrollTo(0, newY);
    },
    draw_article() {
      this.$crmapi.callApi(
        {
          method: 'get',
          kind: 'mailarticle',
          no: this.articleno
        }
      ).then((response) => {
        this.article = response.result
        this.article_org = JSON.parse(JSON.stringify(this.article))
        let inputs = document.querySelectorAll('input[prop], textarea[prop], select[prop]')
        for (let input of inputs) {
          this.set_changed(input)
        }
        if (this.does_already_sent(this.article.ststatus)) {
          document.getElementById('add').disabled = false
          document.getElementById('clear').disabled = false
          document.getElementById('reset').disabled = false
          document.getElementById('change').disabled = true
          document.getElementById('delete').disabled = true
        } else {
          this.abled_buttons()
        }
      })
    },
    get_userinfo() {
      return this.$crmapi.callApi(
        {
          method: 'status',
          mode: '',
          kind: 'company',
          cond: JSON.stringify({ order: 'desc' })
        }
      ).then((response) => {
        this.info = response.result
      })
    },
    draw_mailtemplate() {
      this.$crmapi.callApi(
        {
          method: 'get',
          kind: 'mailtemplate',
          no: this.articleno
        }
      ).then((response) => {
        this.article = response.result
        this.article_org = JSON.parse(JSON.stringify(this.article))
        let inputs = document.querySelectorAll('input[prop], textarea[prop], select[prop]')
        for (let input of inputs) {
          this.set_changed(input)
        }
      })
    },
    get_articles(add = false, reload = false) {
      if (reload) {
        this.from = 0
      }
      return this.$crmapi.callApi(
        {
          method: 'search',
          mode: 'search',
          kind: 'mailarticle',
          cond: JSON.stringify({ order: 'desc' }),
          num: this.num,
          from: this.from
        }
      ).then((response) => {
        this.numall = response.numall;
        if (add) {
            response.result.forEach((alist) => {
            this.articles.push(alist);
          });
          this.update_article = false ;
        } else {
          this.articles = response.result
        }
      })
    },
    get_mailtemplate() {
      return this.$crmapi.callApi(
        {
          method: 'search',
          mode: 'search',
          kind: 'mailtemplate',
          cond: JSON.stringify({ order: 'desc' })
        }
      ).then((response) => {
        this.mailtemplates = response.result
      })
    },
    get_clickurls() {
      this.$crmapi.callApi(
        {
          method: 'search',
          mode: 'list',
          kind: 'insurl',
          property: 'stnamae'
        }
      ).then((response) => {
        this.clickurls = response.result
      })
    },
    get_signatures() {
      this.$crmapi.callApi(
        {
          method: 'search',
          mode: 'list',
          kind: 'signature',
          property: 'stnamae'
        }
      ).then((response) => {
        this.signatures = response.result
      })
    },
    disabled_buttons() {
      for (let btn of document.querySelectorAll('.form button')) {
        if (btn.id !== 'add') {
          btn.disabled = true;
        }
      }
    },
    abled_buttons() {
      for (let btn of document.querySelectorAll('.form button')) {
        btn.disabled = false;
      }
    },
    switch_button_stat(mode) {
      switch (mode) {
        case 'afterselect':
          this.abled_buttons()
          break;
        case 'clear':
          this.disabled_buttons()
          break;
      }
    },
    change() {
      try {
        var  a_name= document.getElementById('exampleFormControlInput1').value
        if (!a_name) {
          throw new Error('原稿名を入力してください。')
        }
        for (let a of this.articles) {
           if (this.articleno == a.inmailtmplno) {
                continue;
              }
          if(a_name == a.stnamae){
            throw new Error('同一原稿名がすでに存在します。別の原稿名にしてください。')
          }
        }
         var s_name= document.getElementById('exampleFormControlInput2').value
        if (!s_name) {
          throw new Error('件名を入力してください。')
        }
        var mail = document.getElementById('txtMailBody').value
        if (!mail) {
          throw new Error('本文を入力してください。')
        }
        var d_name= document.getElementById('exampleFormControlInput3').value
        if (!d_name) {
          throw new Error('配信元名称を入力してください。')
        }
        var adress = document.getElementById('exampleFormControlInput4').value
        if (!adress) {
          throw new Error('配信元メールアドレスを入力してください。')
        }
      } catch(e) {
        alert(e.message)
        return
      }  
      if (!window.confirm('この内容で上書きしてもよろしいでしょうか?')) {
        return
      }
      this.$crmapi.callApi(
        {
          method: 'update',
          kind: 'mailarticle',
          no: this.article.inmailtmplno,
          mode: 'partial',
          info: JSON.stringify(this.article),
          catalog: 'mail'
        }
      ).then((response) => {
        if (response.retval != 0) {
          alert(response.message)
          return
        }
        this.get_articles(false, true)
        this.draw_article()
      })
    },
    add() {
      try {
        var  a_name= document.getElementById('exampleFormControlInput1').value
        if (!a_name) {
          throw new Error('原稿名を入力してください。')
        }
        for (let a of this.articles) {
          if(a_name == a.stnamae){
            throw new Error('同一原稿名がすでに存在します。別の原稿名にしてください。')
          }
        }
        var s_name= document.getElementById('exampleFormControlInput2').value
        if (!s_name) {
          throw new Error('件名を入力してください。')
        }
        var mail = document.getElementById('txtMailBody').value
        if (!mail) {
          throw new Error('本文を入力してください。')
        }
        var d_name= document.getElementById('exampleFormControlInput3').value
        if (!d_name) {
          throw new Error('配信元名称を入力してください。')
        }
        var adress = document.getElementById('exampleFormControlInput4').value
        if (!adress) {
          throw new Error('配信元メールアドレスを入力してください。')
        }
      }catch(e) {
        alert(e.message)
        return
      }  
      if (!window.confirm('この内容で登録してもよろしいでしょうか?')) {
        return
      }
      var a_info = {}

      if (!this.article.inmailtmplno) {
        a_info = {
          stnamae: a_name,
          stfrom: adress,
          sthaishinmoto: d_name,
          stsubject: s_name,
          stbunmen: mail,
          bonetoone:"false",
          language:"ja",
          charset:"ISO-2022-JP",
          stencoding:"ja/ISO-2022-JP",
          sthtml:""
        }
      } else {
        a_info = this.article
      } 
      this.$crmapi.callApi(
        {
          method: 'add',
          kind: 'mailarticle',
          no: 0,
          mode: 'partial',
          info: JSON.stringify(a_info),
          catalog: 'mail'
        }
      ).then((response) => {
        if (response.retval != 0) {
          alert(response.message)
          return
        }
        if (!this.barticle) {
          this.on_article(true);
        }
        this.get_articles(false, true)
        this.articleno = response.no
        this.draw_article() 
        this.get_schedule()
        this.get_test_results()
      })
    },
    delete_article() {
      if (!window.confirm('選択中の原稿を削除します。よろしいでしょうか?')) {
        return
      }
      this.$crmapi.callApi(
        {
          method: 'delete',
          kind: 'mailarticle',
          no: this.article.inmailtmplno,
          catalog: 'mail'
        }
      ).then((response) => {
        if (response.retval != 0) {
          alert(response.message)
          return
        }
        this.get_articles(false, true).then(() => {
          this.disabled_buttons()
        })
        this.article = {}
        this.article_org = {}
      })
    },
    clear() {
      this.article = {}
      this.switch_button_stat('clear')
    },
    reset() {
      this.article = {}
      for (let i in this.article_org) {
        this.article[i] = this.article_org[i]
      }
      let inputs = document.querySelectorAll('input[prop], textarea[prop], select[prop]')
      for (let input of inputs) {
        this.set_changed(input)
      }
    },
    does_already_sent(status) {
      if(status) {
        let stats = status.split('/')
        return (stats.length == 2 && stats[1] == 'sent')
      }
    },
    switch_select(type) {
      if(this.files.length > 0 && type !=='csv'||this.display_files.length > 0 && type !=='csv') {
        if (!confirm('配信リストの種類を変更すると、アップロード済みのCSVリストはすべて削除されます。よろしいですか?')) {
          return
        }
        this.files = []
        this.display_files = []
        this.csv_list = false
      }
      if (type == 'query') {
        this.query_area = true
        this.csv_area = false
        this.get_queries()
      } else if(type == 'csv') {
        this.query_area = false
        this.csv_area = true
      }else{
        this.query_area = false
        this.csv_area = false
      }
    },
    get_query_info() {
      if (!this.queries[0]) {
        this.noquery = true
        this.show_query_name = false
      }else{
        this.noquery = false
        this.show_query_name = true
        this.querynumber = this.queries[0].inno
        this.$crmapi.callApi(
          {
            method: 'get',
            kind: 'query',
            no: this.querynumber
          }
        ).then((response) => {
          this.query_info = response.result
        })
        let qno = document.getElementById('query').selectedIndex
        if(qno >=0) {
          this.querynumber =  this.queries[qno].inno
          this.$crmapi.callApi(
            {
              method: 'get',
              kind: 'query',
              no: this.querynumber
            }
          ).then((response) => {
            this.query_info = response.result
          }) 
        }
      }
    },
    get_queries() {
      this.$crmapi.callApi(
        {
          method: 'list',
          kind: 'query',
          type: this.querykind,
        }
      ).then((response) => {
        this.queries = response.result
        setTimeout(() => this.get_query_info(), 100)
      })
    },
    upload_file() {
      var f = document.getElementById("inputGroupFile04").files
      if(!f[0]) {
        alert("アップロードするファイルを指定してください")
        return
      }
      if(f[0].size > 2097152) {
        alert("ファイルサイズが2MBを超えています")
        return
      }
      var i = {}
      var data = new FormData()
      data.append("MAX_FILE_SIZE", 2097152)
      data.append("filename",f[0],)
      data.append("token",store.state.token,)
      data.append("apiname","upload")
      data.append("kind","mailrecipient",)
      data.append( "info",JSON.stringify(i) )
      var config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      }
      this.axios.post(this.$crmapi.ENDPOINT, data, config) 
        .then((response)=> {
          alert(response.data.result.num + "件の配信先が登録されました。") 
          let d = new Date()
          let fd = this.format_datetime(d)
          response.data.date = fd
          this.files.push(response.data)
          this.csv_list = true
        })
    },
    delete_file() {
      if (!confirm('リストを全削除します。よろしいですか？')) {
        return
      }
      this.files = []
      this.display_files = []
      this.csv_list = false
    },
    open_search_tester() {
      this.at_m_reset()
      this.search_tester()
    },
    search_tester() {
      var cond = {
        stkaisha: document.getElementById('searchTesterCompany').value,
        stnamae: document.getElementById('searchTesterCustomer').value,
        ineigyouno: 0, // 全担当検索
        mode: 'normal',
      }
      this.$crmapi.callApi(
        {
          method: 'search',
          mode: 'search',
          kind: 'customer',
          num: 20,
          cond: JSON.stringify(cond),
        }
      ).then((response) => {
        this.searchtesters = response.result
      })
    },
    get_select_search_tester(item) {
      if (this.selectsearchtester === null) {
        return ''
      }
      if (!this.searchtesters[this.selectsearchtester]) {
        return ''
      }
      return this.searchtesters[this.selectsearchtester][item]
    },
    click_search_tester(event) {
      let tbody = event.currentTarget.parentNode
      for (let tr of tbody.children) {
        tr.className = tr.className.replace(/selected/g, '')
      }
      event.currentTarget.className += ' selected'
      this.selectsearchtester = event.currentTarget.getAttribute('index')
    },
    delete_tester(inmemberno) {
      if (!confirm('テスト配信先から削除します。よろしいですか？')) {
        return
      }
      var testerNos = []
      for (var i of this.tester) {
        if (i.inmemberno != inmemberno) {
          testerNos.push(i.inmemberno)
        }
      }
      this.$crmapi.callApi(
        {
          method: 'savesetting',
          kind: 'mailtester',
          value: JSON.stringify(testerNos),
        }
      ).then(() => {
        this.set_tester()
      })
    },
    add_tester_submit() {
      if (this.selectsearchtester === null) {
        this.searchtesters = []
        return
      }
      var testerNos = [this.searchtesters[this.selectsearchtester].inmemberno]
      for (var i of this.tester) {
        if (!~testerNos.indexOf(i.inmemberno)) {
          testerNos.push(i.inmemberno)
        } else {
          alert("すでにテスト配信先に登録されています")
          return
        }
      }
      this.$crmapi.callApi(
        {
          method: 'savesetting',
          kind: 'mailtester',
          value: JSON.stringify(testerNos),
        }
      ).then(() => {
        this.at_m.hide()
        this.set_tester()
      })
      this.at_m_reset()
    },
    at_m_reset() {
      this.searchtesters = []
      this.selectsearchtester = null
      document.getElementById('searchTesterCompany').value = ""
      document.getElementById("searchTesterCustomer").value = ""
    },
    set_startTime() {
      this.startTime = { hours: new Date().getHours(), minutes: Math.floor(((new Date().getMinutes())/5)+1)*5%60 }
    },
    change_okbutton(schedule) {
      this.change_sch = true
      if (schedule.listwarning) {
        alert(schedule.listwarning)
        this.change_sch = false
        this.modal_disabled()
      }
      this.skoushin = schedule
      this.display_schedule(this.skoushin)
    },
    display_schedule(schedule){
      this.mailkind = schedule.sttype
      this.display_select(schedule)
      let dd = new Date(schedule.tihaisou)
      this.delivery_datetime = dd
    },
    save_schedule() {
      try {
        if (!this.delivery_datetime) {
          throw new Error('配信日時を設定してください。')
        }
        let after5min = new Date()
        after5min.setTime(after5min.getTime() + 300000) // 5分後
        let dd = new Date(this.delivery_datetime)
        if (!this.delivery_datetime || this.delivery_datetime.getTime() < after5min.getTime() || dd.getTime()  < after5min.getTime()) {
          throw new Error   ('配信日時は現在から5分後以降の日時を設定してください。')
        }
        var type = ''
        if (document.getElementById('mailtype_all').checked) {
          type = 'all'
        } else if (document.getElementById('mailtype_query').checked) {
          type = 'query'
          var querykind = document.getElementById('query_kind').value
          if (!querykind) {
            throw new Error('クエリ種別を選択してください。')
          }
          var queryno = document.getElementById('query').value
          if (!queryno) {
            throw new Error('クエリ名を選択してください。')
          }
          var select = document.getElementById('query')
          var option = select.options[select.selectedIndex]
          var queryname = option.innerText
        } else if (document.getElementById('mailtype_csv').checked) {
          type = 'csv'
          if(this.files.length < 1 && this.display_files.length  < 1) {
            throw new Error("配信先CSVファイルを少なくともひとつ以上登録してください。")
          }
        } else {
          throw new Error('配信リストを選択してください。')
        }
      } catch(e) {
        alert(e.message)
        return
      }
      if (!this.change_sch)  {
        var info = {
          stoption:{
            criteria_email: document.getElementById('criteria_one').checked,
            onthefly: document.getElementById('criteria_include').checked
          },
          sttype: type,
          inmailtmplno: this.articleno,
          tihaisou: this.format_datetime(this.delivery_datetime),
          ingmtminute: -540
        }
        if (type == 'query') {
          info.stlist = {
            "name": queryname,
            "no": queryno,
            "kind": querykind,
          }
        }
        if (type == 'csv') {
          let ilist = []
          for(let f of this.files){
            let d = new Date()
            let date =  this.format_datetime(d)
            let st =
              {
                "name": f.filename,
                "date": date,
                "no": f.result.inrecipientno
              }
            ilist.push(st)
          }
          info.stlist = ilist
        }
        if (!confirm('この内容で配信予約します。よろしいですか？')) {
          return
        }
        this.$crmapi.callApi(
          {
            method: 'add',
            kind: 'mailschedule',
            info: JSON.stringify(info),
            no: 0,
            mode: 'partial',
          }
        ).then(() => {
          this.r_m_reset()
          this.r_m.hide()
          this.get_schedule()
        })
      } else {

        var c_info = {
          stoption:{
            criteria_email: document.getElementById('criteria_one').checked,
            onthefly: document.getElementById('criteria_include').checked
          },
          sttype: type,
          inmailtmplno: this.articleno,
          tihaisou: this.format_datetime(this.delivery_datetime),
          ingmtminute: -540,
          inhaisouno : this.skoushin.inhaisouno
        }
        if (type == 'query') {
          c_info.stlist = {
            "name": queryname,
            "no": queryno,
            "kind": querykind,
          }
        }
        if (type == 'csv') {
          let ilist = []
          for(let f of this.files){
            let d = new Date()
            let date =  this.format_datetime(d)
            let st =
              {
                "name": f.filename,
                "date": date,
                "no": f.result.inrecipientno
              }
            ilist.push(st)
          }
          if (this.display_files.length > 0 ) {
            ilist = ilist.concat(this.display_files)
          }
          c_info.stlist = ilist
        }
        if (!window.confirm('この内容で変更してもよろしいでしょうか?')) {
          return
        }
        this.$crmapi.callApi(
          {
            method: 'update',
            kind: 'mailschedule',
            info: JSON.stringify(c_info),
            no: this.skoushin.inhaisouno,
            mode: 'partial',
          }
        ).then(() => {
          this.r_m_reset()
          this.r_m.hide()
          this.get_schedule()
        })
      }
    },
    download(s) {
      let d = new Date()
      if(s.bsent == 't') {
        let filename = 'Sentmail_' + d.getFullYear() + (d.getMonth() +1) + d.getDate() + '.csv'
        this.$crmapi.download_csv(
          {
            method: 'search',
            kind: 'sentmail',
            mode: 'download',
            cond: JSON.stringify({ inhaisouno: s.inhaisouno, sort: 'inmemberno', order: 'asc' }),
            catalog: 'sentmail',
            format: 'csv',
          },
          filename)
      }else{
        let filename = 'Mailschedule_' + d.getFullYear() + (d.getMonth() +1) + d.getDate() + '.csv'
        this.$crmapi.download_csv(
          {
            method: 'search',
            kind: 'mailrecipient',
            mode: 'download',
            cond: JSON.stringify({ inhaisouno: s.inhaisouno, sort: 'inmemberno', order: 'asc' }),
            catalog: 'mail',
            format: 'csv',
          },
          filename)
      }
    },
    delete_schedule(sno){
      if (!window.confirm("No."+ sno +"の配信設定を削除します。よろしいでしょうか?")) {
        return
      }
      this.$crmapi.callApi(
        {
          method: 'delete',
          kind: 'mailschedule',
          no: sno,
          catalog: 'mail'
        }
      ).then((response) => {
        if (response.retval != 0) {
          alert(response.message)
          return
        }
        this.get_schedule()
      })
    },
    setting_modal_display(schedule) {
      this.mailkind = schedule.sttype
      this.display_select(schedule)
      this.delivery_datetime = schedule.tihaisou
      let stop =  JSON.parse(schedule.stoption)
      if(stop.criteria_email) {
        this.criteria_email = "one"
      }else{
        this.criteria_email = "all"
      }
      if(stop.onthefly) {
        this.criteria_onthefly = 'include';
      }else{
        this.criteria_onthefly = 'exclude';
      }
      this.modal_disabled()
    },
    modal_disabled() {
      this.readonly = true
      let inputs = document.querySelectorAll("#reserve_modal input,#reserve_modal select,#reserve_modal .modal-footer button[class$='primary']")
      for(let input of inputs) {
        input.disabled = true
      } 
      let uploads = document.querySelectorAll("#inputGroupFile04,#inputGroupFileAddon04,#delete_file")
      for (let upload of uploads) {
        upload.style.display = "none" 
      }
    },
    display_select(schedule) {
      if (schedule.sttype == 'query') {
        this.query_area = true
        let stob = JSON.parse(schedule.stlist)
        this.display_query_info(stob)
      } else if(schedule.sttype =='csv') {
        this.csv_area = true
        this.csv_list = true
        this.query_area = false
        this.display_csv_info(schedule)
      } else {
        this.csv_area = false
        this.query_area = false
      }
    },
    display_query_info(query) {
      this.querykind = query.kind
      this.querynumber = query.no
      this.$crmapi.callApi(
        {
          method: 'list',
          kind: 'query',
          type: this.querykind,
        }
      ).then((response) => {
        this.queries = response.result
      })
      this.$crmapi.callApi(
        {
          method: 'get',
          kind: 'query',
          no: this.querynumber

        }
      ).then((response) => {
        this.query_info = response.result
      }) 
    },
    display_csv_info(s) {
      let stob = JSON.parse(s.stlist)
      this.display_files = stob
    },
    r_m_reset() {
      this.mailkind = ''
      this.querykind = 'customer'
      this.queries = []
      this.query_info = {}
      this.query_area = false
      this.noquery = false
      this.show_query_name = true
      this.csv_area = false
      this.csv_list = false
      this.files = []
      this.display_files = []
      this.delivery_datetime = null
      this. criteria_onthefly = 'include'
      this.criteria_email = 'all'
      this.change_sch = false
      let inputs = document.querySelectorAll("#reserve_modal input,#reserve_modal select,#reserve_modal .modal-footer button[class$='primary']");
      for(let input of inputs) {
        input.disabled = false
      }
      let uploads = document.querySelectorAll("#inputGroupFile04,#inputGroupFileAddon04,#delete_file")
      for (let upload of uploads) {
        upload.style.display = "block" 
      } 

      this.readonly = false
    },
    get_query_info_cond() {
      if (this.query_info) {
        return this.query_info.cond
      } else {
        return {}
      }
    },
    save_alert (event) {
      if(this.unsave) {
        event.returnValue = "保存していない変更は失われます。よろしいですか？"
      }
    }
  },
  computed: {
    reverseUrls() {
      return this.clickurls.slice().reverse();
    }
  },
  created () {
    window.addEventListener("beforeunload", this.save_alert)
  },
  unmounted () {
    window.removeEventListener("beforeunload", this.save_alert)
  }
}
</script>
<style scoped>
div.wrap {
  border: solid 1px #d5d5d5;
  border-radius: 2px;
  display: inline-block;
  padding: 10px;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}
div.wrap div.form,
div.wrap div.list {
  border: solid 1px #d5d5d5;
  border-radius: 2px;
  padding: 10px;
  box-shadow: 2px 4px 10px 0px #d5d5d5;
  width: 100%;
  height: 850px;
  margin-bottom: 10px;
}
div.testmail,
div.schedule {
  border: solid 1px #d5d5d5;
  border-radius: 2px;
  box-shadow: 2px 4px 10px 0px #d5d5d5;
  padding: 10px;
}
.schedule th {
  vertical-align: bottom;
}
.schedule tbody tr.sent {
  background-color: #ddffdb;
  border: 5px solid white;
}
.schedule tbody tr:not(.sent) {
  background-color: #ffdede;
  border: 5px solid white;
}

 #infinite_scroll {
   overflow-y: scroll;
   max-height: 770px;
 }

 table {
   width: 100%;
   border-collapse: collapse;
 }
 th, .list td {
   text-align: right;
   padding: 3px 0 3px 10px;
   font-weight: normal;
 }
 table.selectable tbody {
   cursor: pointer;
 }
 #article_list tbody tr:hover {
   background-color: #DDF !important;
 }
 #article_list tbody tr td:nth-child(4) {
   white-space: nowrap;
 }
 tr.selected {
   background-color: #BBE;
 }
 div.form button {
   margin-right: 5px;
   width: 120px;
 }
 .changed {
   background-color: #ffeef6;
 }
 .fr {
   float: right;
   margin-left: 5px;
 }
 .ib {
   display: inline-block;
 }
 .clickurl-list {
   display: inline;
   width: auto;
 }
 .form-label {
   width: 18%;
   max-width: 120px;
 }
 .sentence .form-label {
   width: 100%;
 }
 .form-control {
   display: inline-block;
   width: 82%;
 }
 .sentence .form-control {
   display: block;
   width: 100%;
 }
 .subject {
   display: block;
   text-align: left;
 }
 .mb-3 {
   margin-top: 0.4rem;
   margin-bottom: 0.4rem!important;
 }
 #insertClkURL {
   position: absolute;
   background-color: white;
   padding: 8px;
   border: black 1px solid;
 }
 .addresswindow {
   overflow-x: auto;
   overflow-y: hidden;
   width: 100%;
   max-width: 440px;
   height: 1.8rem;
   white-space: nowrap;
 }
 .addresswindow::-webkit-scrollbar {
   height: 6px;
 }
 .addresswindow::-webkit-scrollbar-track{
   background-color: #ccc;
 }
 .addresswindow::-webkit-scrollbar-thumb{
   background-color: #17585d;
 }
 .freverse {
   flex-direction: row-reverse;
 }
 .search-tester-list {
   height: 40vh;
   max-height: 400px;
   overflow-y: auto;
   overflow-x: hidden;
 }
 .search-tester-list table {
   table-layout: fixed;
 }
 .search-tester-list td {
   white-space: nowrap;
   overflow: hidden;
 }
 .search-tester-detail {
   border: 1px solid #dee2e6;
   margin: 10px 5px;
 }
 .search-tester-detail th {
   padding: 0 10px;
   width: 80px;
 }
 .wrapper {
   display: flex;
   justify-content: center;
   padding: 0px;
 }
 .wrapper .icon {
   position: relative;
   padding: 0px;
   margin: 0px;
   transition: all 0.2s;
   cursor: pointer;
 }
 .wrapper .tooltip {
   position: absolute;
   top: 20%;
   left: 80%;
   width: 100px;
   text-aligh: center;
   transform: translate(-50%, -50%);
   padding: 5px 5px;
   background-color: #000000;
   color: white;
   border-radius: 5px;
   pointer-events: none;
   transition: all 0.3s;
 }

 .wrapper .tooltip::before {
   position: absolute;
   content: "";
   bottom: -3px;
   left: 50%;
   transform: translate(-50%) rotate(45deg);
   transition: all 0.3s;
 }
 .wrapper .tooltip::after {
   content: " ";
   position: absolute;
   top: 100%;
   left: 50%;
   margin-left: -5px;
   border-width: 5px;
   border-style: solid;
   border-color: #000000 transparent transparent transparent;
 }
 .wrapper .icon:hover .tooltip {
   top: -45px;
   opacity: 1;
   visibility: visible;
   pointer-events: auto;
 }
 .hover-red:hover {
   color: red;
 }
 .hover-blue {
   text-align: center;
   font-weight: 550;
   text-decoration:underline #4169e1;
 }
 .hover-blue:hover {
   color:  #007bff;
 }
 .section {
   line-height: 2.6;
   font-size: 1.1em;
   font-weight: 550;
   border-bottom: 2px dotted ;
   border-color: #007bff
 }
 .modal_table {
   border-collapse: separate;
   text-indent: initial;
   border-spacing: 2px;
 }
 .modal_table th {
   background: #b8daff none repeat scroll 0 0;
   border-bottom: 1px solid #00008b;
   border-top: 1px solid #00008b;
   border-left: 1px solid #CCC;
   line-height: 1.1;
   color: #007bff;
   padding: 2px 14px;
   text-align: center;
 }
 .modal_table td {
   background: white none repeat scroll 0 0;
   border-bottom: 1px solid #CCC;
   color: #333;
   padding: 2px 4px;
 }
 #test_help ul li {
   padding-top:5px; 
   padding-bottom:5px;
 }
 .modal-body .table-hover th:first-child,
 .modal-body .table-hover td:first-child {
   width: 50%;
 }
 .modal-body .table-hover th:nth-child(n + 2),
 .modal-body .table-hover td:nth-child(n + 2) {
   width: 25%;
 }
 .modal-body .table-hover td div {
   white-space: nowrap;
   overflow: hidden;
 }

 #loading {
   text-align: center;
   color: #ff9800;
   font-weight: bold;
 }
 .blue {
   color: #007bff;
 }
 .gray {
   color: #696969;
 }
 .white {
   color: #d3d3d3;
 }
 .pink {
   color: #ffbcbc;
 }
 .green {
   color: #bcffbc;
 }
 .bold {
   font-weight: bold;
 }
.text-justify {
   text-align: justify;
}
.section-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.section_item {
  margin-right: 20px;
}
.title {
  font-size: 1.2rem;
}
</style>
