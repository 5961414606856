import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import crmapi from '@/crmapi'

// 各画面のViewオブジェクトのロード
import LoginView from '../views/LoginView.vue'
//import HomeView from '../views/HomeView.vue'
import EditView from '../views/EditView.vue'
import DeliveryScheduleResultView from '../views/DeliveryScheduleResultView.vue'
import ClickUrlView from '../views/ClickUrlView.vue'
import EnqueteView from '../views/EnqueteView.vue'

const DEFAULT_TITLE = 'メール配信'

const routes = [
  {
    path: '/',
    redirect: 'scheduleresult'
  },
  {
    path: '/edit',
    name: 'edit',
    component: EditView,
    meta: { title: '原稿設定' }
  },
  {
    path: '/scheduleresult',
    name: 'scheduleresult',
    component: DeliveryScheduleResultView,
    meta: { title: '配信予定・結果' }
  },
  {
    path: '/clickurl',
    name: 'clickurl',
    component: ClickUrlView,
    meta: { title: 'クリック測定URL' }
  },
  {
    path: '/enquete',
    name: 'enquete',
    component: EnqueteView,
    meta: { title: 'アンケート設定' }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { title: 'ログイン' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  if (to.meta.title) {
    document.title = DEFAULT_TITLE + ' - ' + to.meta.title
  } else {
    document.title = DEFAULT_TITLE
  }
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    next()
    return
  }
  // ログイン画面以外にアクセスされる場合はログインチェックを実施
  if (to.query.token) {
    // 旧システムからのリンク遷移
    store.commit('saveToken', to.query.token)
  }
  if (to.query.id) {
    // 旧システムからのリンク遷移
    store.commit('saveCompany', to.query.id)
  }
  let company = store.state.company
  if (!store.state.token) {
    // token が無い
    next({ path: '/login', query: { redirect: to.fullPath, id: company } })
  } else {
    crmapi.checkTimeout().then(() => {
      next()
    })
    .catch((reason) => {
      // タイムアウト 再ログイン
      next({ path: '/login', query: { redirect: to.fullPath, message: reason, id: company }})
    })
  }
})

export default router
