import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    token: '',
    uid: '',
    company: '',
  },
  getters: {
  },
  mutations: {
    saveToken (state, token) {
      state.token = token
    },
    saveUid (state, uid) {
      state.uid = uid
    },
    saveCompany (state, company) {
      state.company = company
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    // state のデータを localStorage で保持
    createPersistedState({
      key: 'appState',
      storage: window.localStorage
    })
  ]
})
