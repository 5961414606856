<template>
  <h2>クリック測定<span class="me-2"/><font-awesome-icon icon="fas fa-question-circle" class="fa-lg blue" data-bs-toggle="modal" data-bs-target="#main_help"/></h2>
  <div class="container-fluid">
    <div class="row freverse">
      <div class="col-xxl mb-2" id="urllist">
        <h3 class="title">クリック測定URL一覧<span class="me-2"/><font-awesome-icon icon="fas fa-question-circle" class="fa-lg blue" data-bs-toggle="modal" data-bs-target="#cu_list_help"/></h3>
        <div class="list">
          <div class="search">
            <div class="search-box">
              <div id="url-box">
                <label for="seacrh_url">クリック測定名/URL</label>
                <input type="text" class="form-control" id="seacrh_url" placeholder="" v-model="url_name">
              </div>
              <div id="tag-box">
                <label for="seacrh_tag">種別タグ名</label>
                <input type="text" class="form-control" id="seacrh_tag" placeholder="" v-model="tag_name">
              </div>
              <div>
                <span>期間</span>
                <RectusPeriodpicker v-model="url_date_for_search"/>
              </div>
              <button id="search_data" class="btn btn-outline-primary" @click="get_clickurls(true, false, false)">検索</button><button id="clear_data" type="button" class="btn btn-outline-info" @click="clear_data">クリア</button>
            </div>
            <div class="file-box mb-3">
              <div class="input-group">
                <input type="file" id="inputGroupFile04" accept=".csv"   aria-describedby="inputGroupFileAddon04" aria-label="Upload">
                <button class="btn btn-outline-secondary btn-sm" type="button" id="inputGroupFileAddon04" @click="upload_file" >アップロード</button>
              </div>
              <div class='text-center'>
                <button class="btn btn-primary" @click="download_file">CSVダウンロード</button>
              </div>
            </div>
            <div class='list-margin'></div>
          </div>
          <div class="innerlist" id="infinite_scroll" ref="scroll_area">
            <table class="table table-striped table-hover selectable" id="url_list">
              <colgroup>
                <col style="width: 50px;">
              <col style="width: 400px;">
              <col style="width: 100px;">
              <col style="width: 100px;">
              <col style="width: 50px;">
              <col style="width: 50px;">
            </colgroup>
              <thead class="sticky-top bg-white">
                <tr>
                  <th rowspan="2" class='text-center'>No.</th>
                  <th rowspan="2" class='text-center'>クリック測定URL名<br>タグ名</th>
                  <th rowspan="2" class='text-center'>ｑ値<br>通知メール</th>
                  <th rowspan="2" class='text-center'>更新日時</th>
                   <th class='text-center nowrap'>クリック</th>
                   <th rowspan="2" class='text-center'>詳細</th>
                </tr>
                <tr>
                  <th class='text-center'>回数<br>人数</th>
                </tr>
              </thead>
              <tbody id="url-table">
                <tr v-for="cu in clickurls" :key="cu.ininsurlno" :no="cu.ininsurlno" @click="this.tr_click($event)">
                  <td class='text-center'>{{ cu.ininsurlno }}</td>
                  <td><a :href= "cu.sturl" rel="noreferrer noopener nofollow" target="_blank">{{ cu.stnamae }}</a><span class="me-1"/><font-awesome-icon icon="fas fa-external-link-alt" class="blue"/><br>{{ cu.sttag }}</td>
                  <td class='text-center'>{{ cu.stq }}<br>{{cu.stnotify }}</td>
                  <td class='text-center'>{{ cu.tikoushin }}</td>
                   <td class='text-end'>
                  <template v-if="cu.inuse == 0"><div><span>{{ cu.inuse }}</span></div></template>
                  <template v-else><div><span name="inusenum" class="download-link" @click="download_num(cu.ininsurlno)">{{ cu.inuse }}</span></div></template>
                  <template v-if="cu.inperson == 0"><div><span name="inpersonnum">{{ cu.inperson }}</span></div></template>
                  <template v-else><div><span name="inpersonnum" class="download-link" @click="download_num(cu.ininsurlno)">{{ cu.inperson }}</span></div></template>
                   </td>
                   <td class='text-end'>
                  <template v-if="cu.inuse == 0"><div><span></span></div></template>
                  <template v-else><span><a :href="go_page(cu)" target="_blank"><font-awesome-icon icon="fas fa-list-alt"/></a></span></template>
                   </td>
                </tr>
                 <tr v-show = " 0 < this.numall - this.clickurls.length">
                  <td colspan="5" id="loading">読み込み中...</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div id="urledit" class="col-xxl mb-2">
        <h3 class="title">新しいURLを登録<span class="me-2"/><font-awesome-icon icon="fas fa-question-circle" class="fa-lg blue" data-bs-toggle="modal" data-bs-target="#cu_edit_help"/></h3>
        <div class="form">
          <div class="text-end" id="editbtn">
            <button  id="change" type="button" class="btn btn-outline-primary btn-lg" @click="change_url">変更</button>
            <button id="add" type="button" class="btn btn-outline-success btn-lg" @click="add_url">追加</button>
             <button id="reset" type="button" class="btn btn-outline-dark btn-lg" @click="reset">リセット</button>
            <button  id="clear" type="button" class="btn btn-outline-info btn-lg"  @click="clear">クリア</button>
            <button  id="delete" type="button" class="btn btn-danger btn-lg" @click="delete_url">削除</button>
          </div>
          <div class="input_area">
            <div class="nichiji-box mb-4 mt-3">
              <div>登録日時:<span class="me-1"/><span class="blue bold me-4">{{ url.titouroku }}</span></div>
              <div>更新日時:<span class="me-1"/><span class="blue bold">{{ url.tikoushin }}</span></div>
            </div>
            <div class="mb-4">
              <div>
              <label for="exampleFormControlInput6">クリック測定URL名</label>
              </div>
              <input type="text" class="form-control" id="exampleFormControlInput6" placeholder="" v-model="url.stnamae" prop="stnamae">
              </div>
            </div>
            <div class="mb-4">
              <div>
              <label for="exampleFormControlInput7">種別タグ</label><span class="me-2"/>(タグが複数ある場合は、スペースで区切って入力します。)
              </div>
              <input type="text" id="exampleFormControlInput7" class="form-control" placeholder="" v-model="url.sttag" prop="sttag">
                         </div>
            <div class="mb-4">
              <div>
              <label for="url">設定URL</label>
              </div>
              <div class="search-box"><input type="text" id="url" class="form-control" placeholder="" v-model="url.sturl" prop="sturl"><button class="btn btn-primary btn-sm" id="lcheck" @click="check_url">リンクチェック</button></div>
             <div class="mt-4 mb-1 radio-box">
              確認メール通知<span class="me-4"/>
               <div class="form-check">
                <input class="form-check-input" type="radio" id='f_bmail' value='f_bmail' name="bmail" v-model="bmail" @change="switch_bmail($event.currentTarget.value)">
                <label class="form-check-label" for="f_bmail">送信しない</label>
              </div><span class="me-2"/>
              <div class="form-check">
                <input class="form-check-input" type="radio" id='t_bmail'  value='t_bmail' name="bmail" v-model="bmail" @change="switch_bmail($event.currentTarget.value)">
                <label class="form-check-label" for="t_bmail">送信する</label>
              </div>
             </div>
             <div class="form-check">
               <input class="form-check-input" type="checkbox" id="bmailadmin" value="bmailadmin" v-model="bmailadmin" :disabled="bmail_setting">
               <label class="form-check-label" for="bmailadmin">システム初期メールアドレス<span class="me-2"/>({{ ask_email}})</label>
             </div>
              <div class="form-check">
               <input class="form-check-input" type="checkbox" id="bmailother"  value="bmailother" v-model="bmailother" @change="abled_adressform($event.currentTarget)" :disabled="bmail_setting">
               <label class="form-check-label" for="bmailother">追加メールアドレス<span class="me-2"/>(アドレスが複数ある場合は、1行ごとに入力するか、",(カンマ)"で区切って入力します。)
</label>             </div>
             <div class="search-box"><textarea id="addmail" class="form-control"  placeholder="" v-model="stemail" :disabled="add_adress"></textarea><button class="btn btn-primary btn-sm" id="acheck" @click="check_adress" :disabled="add_adress">アドレスチェック</button></div>
             <div class="mt-4 radio-box">
              ダウンロード認証時のメール通知<span class="me-4"/>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="bnotifytipe" value="always" id="always" v-model="bnotifytype" :disabled="bmail_setting">
                <label class="form-check-label" for="alltime">常に通知する</label>
              </div><span class="me-2"/>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="bnotifytype" id="nodownload" value="nodownload" v-model="bnotifytype" :disabled="bmail_setting">
                <label class="form-check-label" for="nodownload">ダウンロード認証用アンケート回答時は通知しない</label>
              </div>
             </div>
            </div>
        </div>
      </div>
    </div>
  </div>
   <div class="modal fade help" id="main_help" tabindex="-1" aria-labelledby="main_help" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title bold" id="main_help">クリックURL設定画面<span class="me-2"/>ヘルプページ</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <span class="section">目次</span>
          <div class="section-box">
            <div class="section-item"><a href="#cu_explain">全般説明</a></div>
            <div class="section-item"><a href="#cu_use">クリックURLの使い方</a></div>
            <div class="section-item"><a href="#cu_mail">メールへの挿入方法</a></div>
            <div class="section-item"><a href="#cu_download">ダウンロード認証</a></div>
            <div class="section-item"><a href="#cu_download_set">認証タグ設置方法</a></div>
          </div>
          <hr>
          <span class="section" id="cu_explain">全般説明</span>
            <p>クリック測定はメール配信時とダウンロード認証時にどのURLがクリックされたかを測定するために使用します。</p>
            <p>この画面では、クリック測定URLの登録・編集と、結果の確認ができます。<br>
          クリック測定URLの登録・編集する方法は、「新しい原稿を登録する」の<span class="me-2"/><font-awesome-icon icon="fas fa-question-circle" class="fa-lg blue"/><span class="me-2"/>から確認できます。<br>登録したクリック測定URLの結果を確認する方法は、「クリック測定URL一覧」の<span class="me-2"/><font-awesome-icon icon="fas fa-question-circle" class="fa-lg blue"/><span class="me-2"/>から確認できます。</p>
          <hr>
          <span class="section" id="cu_use">クリックURLの使い方</span>
            <p>メール配信時に誰がどのURLをいつクリックしたかを記録します。URLとしては外部サイトのURLやHTML以外のPDFやExcelのファイルなどを指定します。普通のHTMLページは登録しないことをお勧めします。クリック測定ではロボットのクリックも含まれることと、クリックだけしてすぐ閉じた場合の判別ができないためです。</p>
          <hr>
          <span class="section" id="cu_use">メールへの挿入方法</span>
          <p>メール本文にクリック測定のURLを挿入することができます。<br>メール配信の「原稿設定」ページの「原稿編集」の本文のエリア内で右クリックを行うと、メニューが表示されます。「クリック測定」にカーソルを移動させると、登録したURLのリストが表示されます。リストから挿入したいURLを選択し、クリックすればメール本文にURLが挿入されます。</p>
          <hr>
          <span class="section" id="cu_download">ダウンロード認証</span>
            <p>PDF 等のログ解析のタグが埋められないページのダウンロード認証を行う場合にご使用いただけます。<br>リンク先ページはクリック測定の新規登録から「設定URL」を設定、もしくは詳細/変更の「設定URL」から確認できます。</p>
            <p class="red">※アンケートフォームが埋まっているページをクリック測定に設定すると顧客情報が「お客様情報欄」に代入されませんので、アンケートページはクリック測定には設定しないようにしてください。</p>
          <hr>
          <span class="section" id="cu_download_set">認証タグ設置方法</span>
          <p>登録したクリック測定の「 q 値」を使用します。以下タグの data-recdl の「q 値」を書き換えてリンクを設置してください。<br>&lt;a href="#" data-recdl="q値" target="_blank"&gt;<br>
          <br>初期値に設定したダウンロード認証用ページ以外のページに遷移させたい場合は以下のタグを使用します。data-recdl-e に遷移させたいページのリンクを設定してください。<br>&lt;a href="#" data-recdl="q値" data-recdl-e="https://xxx.co.jp/xxx/xxx" target="_blank"&gt;
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade help" id="cu_edit_help" tabindex="-1" aria-labelledby="article_help" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title bold" id="cu_edit_help">新しいURLを登録<span class="me-2"></span>ヘルプページ</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <span class="section">目次</span>
          <div class="section-box">
            <div class="section-item"><a href="#edit_explain">全般説明</a></div>
            <div class="section-item"><a href="#new_cu">新しくクリック測定URLを編集し登録する</a></div>
            <div class="section-item"><a href="#edit_cu">登録済みのクリック測定URLの設定を変更する</a></div>
          </div>
          <hr>
          <span class="section" id="mail_explain">全般説明</span>
          <p>このエリアでは、クリックURLを編集・登録します。<br>また、登録済みのクリック測定URLを編集することができます。</p>
          <hr>
          <span class="section" id="new_cy">新しくクリック測定URLを編集し登録する</span>
          <p>左側の「新しいURLを登録」のエリアで以下の項目を入力設定し、クリック測定URLを編集します。</p>
          <table class="modal_table">
            <thead>
              <tr>
                <th>項目</th><th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>クリック測定URL名</td><td>メール配信システムに登録するクリック測定URL名。配信先には表示されない<span class="me-2"/><span class="red">※入力必須</span></td></tr>
              <tr><td>種別タグ</td><td>任意の「種別」をタグとして設定可能。設定すると絞込み検索時に便利。タグが複数ある場合は、スペースで区切って入力する</td></tr>
              <tr><td>設定URL</td><td>実際に表示させるURL。例) http://www.apto-service.com/service/marketing/example/exhibition.html<span class="red">（“http://”から入力）</span>
                  URLに間違いがないかは<span class="me-2"/><button class="btn btn-primary btn-sm" id="lcheck" disabled>リンクチェック</button><span class="me-2"/>で確認できる<span class="me-2"/><span class="red">※入力必須</span></td></tr>
              <tr><td>確認メール通知</td><td><input class="form-check-input" type="radio" checked disabled><label class="form-check-label" for="f_bmail">送信する</label><span class="me-2"/>
を選択すると、メール受信者がURLをクリックした際、任意のメールアドレスへメール通知することができる。<input class="form-check-input" type="radio" checked disabled><label class="form-check-label" for="f_bmail">送信しない</label><span class="me-2"/>
を選択すると、確認メール通知は送られてこない</td></tr>
            </tbody>
          </table>
          <br>
          <p>確認メール通知の項目で<span class="me-2"/><input class="form-check-input" type="radio" checked disabled><label class="form-check-label" for="f_bmail">送信する</label><span class="me-2"/>を選択した場合、以下の項目を設定します。</p>
           <table class="modal_table">
            <thead>
              <tr>
                <th>項目</th><th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>システム初期アドレス</td><td><input class="form-check-input" type="checkbox" checked disabled><span class="me-2"/>をつけると、設定した初期アドレス({{ ask_email}})に確認メール通知が送信される</td></tr>
              <tr><td>追加メールアドレス</td><td><input class="form-check-input" type="checkbox" checked disabled><span class="me-2"/>をつけると、初期アドレス以外のアドレスに確認メール通知を送信できる。アドレスが複数ある場合は、1行ごとに入力するか、",(カンマ)"で区切って入力する。アドレスに間違いがないかは<span class="me-2"/><button class="btn btn-primary btn-sm" id="lcheck" disabled>アドレスチェック</button><span class="me-2"/>で確認できる</td></tr>
              <tr><td>ダウンロード認証時のメール通知</td><td>ダウンロード認証用アンケート回答時にも確認メール通知を送信するかどうかを設定する</td></tr>
            </tbody>
          </table>
          <br>
          <p>すべての項目を入力し、<button type="button" class="btn btn-outline-success btn-sm" disabled>追加</button><span class="me-2"/>をクリックすれば、原稿が登録され、右側の「クリック測定URL一覧」のエリアに表示されます。</p>
          <hr>
          <span class="section" id="mail_edit">登録済みのクリック測定URLの設定を変更する</span>
          <p>右側の「クリック測定URL一覧」のエリアから登録済みの原稿を選んで設定を変更することができます。</p>
          <p>それぞれのボタンからクリック測定URLの変更・登録・削除ができます。</p>
          <button type="button" class="btn btn-outline-primary btn-sm" disabled>変更</button>
          <p>変更したクリック測定URLの内容を上書き保存します。</p>
          <button type="button" class="btn btn-outline-success btn-sm" disabled>追加</button>
          <p>編集したクリック測定URLを保存し、一覧に新しく追加します。 <button type="button" class="btn btn-outline-primary btn-sm" disabled>変更</button>&ensp;
          とは違い、元のクリック測定URLも一覧に残ります。</p>
          <button type="button" class="btn btn-outline-dark btn-sm" disabled>リセット</button>
          <p>新たに編集した内容がリセットされ元通りになります。</p>
          <button type="button" class="btn btn-outline-info btn-sm" disabled>クリア</button>
          <p>すべての項目の入力内容が消去されます。</p>
          <button type="button" class="btn btn-danger btn-sm" disabled>削除</button>
          <p>クリック測定URLをシステムから削除します。 </p>     
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade help" id="cu_list_help" tabindex="-1" aria-labelledby="main_help" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title bold" id="cu_list_help">クリック測定URL一覧<span class="me-2"/>ヘルプページ</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <span class="section">目次</span>
          <div class="section-box">
            <div class="section-item"><a href="#explain">全般説明</a></div>
            <div class="section-item"><a href="#csv">CSVダウンロード・CSVアップロード</a></div>
            <div class="section-item"><a href="#cu">No.・クリック測定URL名・タグ名</a></div>
            <div class="section-item"><a href="#cu_info">q値・通知メール・更新日時</a></div>
            <div class="section-item"><a href="#cu-download">クリック回数・クリック人数・詳細</a></div>
          </div>
          <hr>
          <span class="section" id="explain">全般説明</span>
            <p>このエリアでは、クリック測定URLの一覧が表示されます。<br>クリックURLの設定を変更したり、クリック結果を確認できたりします。<br>クリック測定URLは、クリック測定名/設定URLと種別タグと期間から絞り込み検索ができます。<br>クリック結果については、<span class="download-link" disabled></span><span class="me-2"/>からCSVファイルでダウンロードすることができます。</p>
          <hr>
          <span class="section" id="csv">CSVダウンロード・CSVアップロード</span>
            <p><button class="btn btn-primary btn-sm" disabled>CSVダウンロード</button><span class="me-2"/>からクリック測定URLの設定情報のcsvファイルをダウンロードできます。<br>クリック測定名/設定URLと種別タグと期間から、ダウンロードしたいクリック測定を絞り込み検索することができます。</p><p>クリック測定URLの設定情報のcsvファイルを <input type="file" id="inputGroupFile04" accept=".csv"   aria-describedby="inputGroupFileAddon04" aria-label="Upload" disabled>
            <button class="btn btn-outline-secondary btn-sm" type="button" id="inputGroupFileAddon04" disabled >アップロード</button><span class="me-2"/>からアップロードすることで、クリック測定URLを一括で追加・更新することができます。</p>
          <hr>
          <span class="section" id="cu">No.・クリック測定URL名・タグ名</span>
          <p>クリック測定URLの主な項目が表示されます。</p>
          <table class="modal_table">
            <thead>
              <tr>
                <th>項目</th><th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>No.</td><td>そのクリック測定URL固有の番号</td></tr>
              <tr><td>クリック測定URL名</td><td>クリック測定URLの名前。<span class="blue">各クリック測定URL名のリンク</span>をクリックすると、設定URL先に遷移して確認ができる</td></tr>
              <tr><td>タグ名</td><td>種別タグ名。登録してあればクリック測定URL名の下に表示される</td></tr>
            </tbody>
          </table>
          <hr>
          <span class="section" id="cu_info">q値・通知メール・更新日時</span>
          <p>クリック測定URLの詳細な情報が表示されます。</p>
          <table class="modal_table">
            <thead>
              <tr>
                <th>項目</th><th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>q値</td><td>そのクリック測定URL固有のq値。認証タグ設置の際に使用する</td></tr>
              <tr><td>通知メール</td><td>確認メール通知を送信する/しないのステータス</td></tr>
              <tr><td>更新日時</td><td>最後にそのクリック測定URLの設定を更新した日時</td></tr>
            </tbody>
          </table>
          <hr>
          <span class="section" id="cu_download">クリック回数・クリック人数・詳細</span>
          <p>URLの測定結果が表示されます。<br>上の行の「回数」がURLがクリックされた回数で、下の行の「人数」がクリックした人の合計人数です。それぞれの<span class="me-2"/><span class="download-link" disabled></span><span class="me-2"/>をクリックすると、CSVリストをダウンロードできます。また、期間を絞って検索することで、その期間内のクリック結果を取得することもできます。<br>詳細の列の<span class="me-2"/><font-awesome-icon icon="fas fa-list-alt" class="blue fa-lg"/><span class="me-2"/>をクリックすると、クリック測定の詳細ページに移動します。</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  </template>
<script>
   import store from '@/store'
   export default {
     name: 'ClickUrlView',
     data() {
       return {
         clickurls: [],
         url: {},
         url_org: {},
         urlno: null,
         url_name: "",
         tag_name: "",
         url_date_for_search: null,
         after_url_name: "",
         after_tag_name: "",
         after_url_date_for_search: null,
         update_url: false,
         from: 0,
         num: 20,
         numall: 0,
         bmail: "f_bmail",
         bnotifytype: "",
         bmailadmin: false,
         bmailother: false,
         stemail: "",
         bmail_setting: true,
         add_adress: true,
         ask_email: "",
         start_this_month: null,
         end_this_month: null,
         start_last_month: null,
         end_last_month: null
       }
     },
     mounted() {
       this.get_clickurls(false, false, false)
       this.disabled_buttons()
       this.set_askemail()
       let element = document.getElementById('infinite_scroll')
       element.addEventListener('scroll', this.on_scroll)
       element.scroll({ top:0, behavior: 'smooth' });
     },
     methods: {
       set_askemail() {
         this.$crmapi.callApi(
           {
             method: 'loadsetting',
             kind: 'company'
           }
         ).then((response) => {
            this.ask_email = response.result.staskemail;
          })
        },
        get_clickurls(click = false, add = false, reload = false) {
          let urlname = ""
          let tag_name = ''
          let period = ''
          if (click) {
            urlname = this.url-name
            tag_name = this.tag_name;
            period = this.url_date_for_search;
            this.after_url_name = this.url_name;
            this.after_tag_name = this.tag_name;
            this.after_url_date_for_search = this.url_date_for_search;
            this.from = 0
            let element = document.getElementById('infinite_scroll')
            element.scroll({ top:0, behavior: 'smooth' });
          }
          if (reload) {
            this.from = 0
          }
          if (this.after_url_name) {
            urlname = this.after_url_name
          } 
          if (this.after_tag_name) {
            tag_name = this.after_tag_name
          }
          if (this.after_url_date_for_search) {
            period = this.after_url_date_for_search
          }
          this.$crmapi.callApi(
            {
              method: 'search',
              mode: 'search',
              kind: 'insurlresult',
              cond: JSON.stringify({"nameurl":urlname,"sttag":tag_name,"stperiod":this.format_date(period),"sort":"ininsurlno","order":"desc"}),
              catalog: 'urlresult',
              num: this.num,
              from: this.from
            }
          ).then((response) => {
            this.numall = response.numall;
            if (add) {
              response.result.forEach((list) => {
              this.clickurls.push(list);
              });
              this.update_url = false ;
            } else {
              this.clickurls = response.result
            }
          })
        },
        clear_data() {
          this.url_name = ""
          this.tag_name = ""
          this.url_date_for_search = null
          this.afetr_url_name = ""
          this.after_tag_name = ""
          this.after_url_date_for_search = null
        },
        tr_click(event) {
          let tbody = event.currentTarget.parentNode
          for (let tr of tbody.children) {
            tr.className = tr.className.replace(/selected/g, '')
          }
          event.currentTarget.className += ' selected'
          this.urlno = event.currentTarget.getAttribute('no')
          this.draw_url()
          this.switch_button_stat('afterselect');
        },
        draw_url() {
          this.$crmapi.callApi(
            {
              method: 'get',
              kind: 'clickurl',
              no: this.urlno
            }
          ).then((response) => {
            this.url = response.result
            this.url_org = JSON.parse(JSON.stringify(this.url))
            let inputs = document.querySelectorAll('.form input[prop]')
            for (let input of inputs) {
              this.set_changed(input)
            }
            this.abled_buttons()
            this.display_url_setting(this.url)
          })
        },
        set_check_update_event() {
          let inputs = document.querySelectorAll('.form input[prop]')
          for (let input of inputs) {
            input.addEventListener('change', (e) => {
              this.set_changed(e.currentTarget)
            }, true)
          }
        },
        set_changed(elem) {
          let prop = elem.getAttribute('prop')
          if (this.url[prop] == this.url_org[prop]) {
            elem.className = elem.className.replace(/changed/g, '')
            this.unsave = false
          } else if (!~elem.className.indexOf('changed')) {
            elem.className += ' changed'
            this.unsave = true
          }
        },
       display_url_setting(url) {
         if (url.bmail == "t") {
           this.bmail = "t_bmail"
           this.bmail_setting = false
           if (url.bnotifytype == "always") {
             this.bnotifytype = "always"
           } else if (url.bnotifytype == "nodownload") {
             this.bnotifytype = "nodownload"
           }
         } else {
           this.bmail = "f_bmail"
           this.bmail_setting = true
           this.bnotifytype = ""
           this.bmailadmin = false
           this.bmailother = false
           this.stemail = ""
           this.add_adress = true
         }
         if (url.bmailadmin =="t") {
           this.bmailadmin = true
         } else {
           this.bmailadmin = false
         }
         if (url.bmailother =="t") {
           this.bmailother = true
           this.add_adress = false
           this.display_adress(url)
         } else {
           this.bmailother = false
           this.add_adress = true
           this.stemail = ""
         }
       },
       display_adress(url) {
         let adress = url.stemail
         //adress = adress.split("\n")
         //adress.join(",")
         this.stemail = adress
       },
        disabled_buttons() {
          for (let btn of document.querySelectorAll('.form button')) {
            if (btn.id !== 'add' && btn.id !== 'lcheck' && btn.id !== 'acheck') {
              btn.disabled = true;
            }
          }
        },
        abled_buttons() {
          for (let btn of document.querySelectorAll('.form button')) {
            if ( btn.id !== 'acheck') {
              btn.disabled = false;
            }
          }
        },
       switch_button_stat(mode) {
         switch (mode) {
           case 'afterselect':
             this.abled_buttons()
             break;
           case 'clear':
             this.disabled_buttons()
             break;
         }
       },
       clear() {
         this.url = {}
         this.switch_button_stat('clear')
       },
       reset() {
         this.url = {}
         for (let i in this.url_org) {
           this.url[i] = this.url_org[i]
         }
         let inputs = document.querySelectorAll('.form input[prop]')
         for (let input of inputs) {
           this.set_changed(input)
         }
       },
       add_url() {
         try {
           var namae= document.getElementById('exampleFormControlInput6').value
           if (!namae) {
             throw new Error('クリック測定URL名を入力してください。')
           }
           for (let c of this.clickurls) {
             if (namae == c.stnamae) {
               throw new Error('同一名のクリック測定URLがすでに存在します。違うクリック測定URL名に変更をお願いします。')
             }
           }
           var url = document.getElementById('url').value
           if (!url) {
             throw new Error('設定URLを入力してください。')
           }
           for (let c of this.clickurls) {
              if (url == c.sturl) {
                throw new Error('同一URLのクリック測定URLがすでに存在します。すでに登録されているURLは設定できません。')
              }
            }
            var bmail = document.getElementById('t_bmail').checked
            var bmailadmin = document.getElementById('bmailadmin').checked
            var bmailother = document.getElementById('bmailother').checked
            var adress= document.getElementById('addmail').value
            if (bmail && !bmailadmin) {
              if (!bmailother || bmailother && !adress ) {
                throw new Error('「確認メール通知」を「送信する」場合は、「システム初期値メールアドレス」か「追加メールアドレス」のどちらかは必ずご選択ください。両方を選択することも可能です。「追加メールアドレス」をご選択された場合は、アドレスをご記入ください。')
              }
            }
          } catch(e) {
            alert(e.message)
            return
          } 
          let tag = document.getElementById("exampleFormControlInput7").value
          let ntype = ''
          if (document.getElementById("nodownload").checked) {
            ntype = "nodownload"
          } else {
            ntype = "always"
          }
          let mail = ''
          if (document.getElementById("f_bmail").checked) {
            mail = "f"
          } else if (document.getElementById("t_bmail").checked) {
            mail = "t"
          }
          var info = {
            stnamae: namae,
            sttag: tag,
            sturl: url ,
            bmail: mail,
            bmailadmin: document.getElementById("bmailadmin").checked,
            bmailother: document.getElementById("bmailother").checked,
            stemail: adress,
            bnotifytype: ntype,
            inscore: ''
          }
          var option = {}
          if (!window.confirm('この内容で登録してもよろしいでしょうか?')) {
            return
          }
          this.$crmapi.callApi(
            {
              method: 'add',
              kind: 'clickurl',
              catalog: 'clickurl',
              no: 0,
              mode: 'partial',
              info: JSON.stringify(info),
              option:JSON.stringify(option),
              format: 'json'
            }
          ).then((response) => {
            if (response.retval != 0) {
              alert(response.message)
              return
            }
            this.get_clickurls(false, false, true)
            this.urlno = response.no
            this.draw_url() 
          })
        },
        change_url() {
          try {
            var namae= document.getElementById('exampleFormControlInput6').value
            if (!namae) {
              throw new Error('クリック測定URL名を入力してください。')
            }
            for (let c of this.clickurls) {
              if (this.urlno == c.ininsurlno) {
                continue;
              }
              if (namae == c.stnamae) {
                throw new Error('同一名のクリック測定URLがすでに存在します。違うクリック測定URL名に変更をお願いします。')
              }
            }
            var url = document.getElementById('url').value
            if (!url) {
              throw new Error('設定URLを入力してください。')
            }
            for (let c of this.clickurls) {
              if (this.urlno == c.ininsurlno) {
                continue;
              }
              if (url == c.sturl) {
                throw new Error('同一URLのクリック測定URLがすでに存在します。すでに登録されているURLは設定できません。')
              }
            }
            var bmail = document.getElementById('t_bmail').checked
            var bmailadmin = document.getElementById('bmailadmin').checked
            var bmailother = document.getElementById('bmailother').checked
            var adress = document.getElementById('addmail').value
            if (bmail && !bmailadmin) {
              if (!bmailother || bmailother && !adress ) {
                throw new Error('「確認メール通知」を「送信する」場合は、「システム初期値メールアドレス」か「追加メールアドレス」のどちらかは必ずご選択ください。両方を選択することも可能です。「追加メールアドレス」をご選択された場合は、アドレスをご記入ください。')
              }
            }
          }catch(e) {
            alert(e.message)
            return
          } 
          let tag = document.getElementById("exampleFormControlInput7").value
          let ntype = ''
          if (document.getElementById("nodownload").checked) {
            ntype = "nodownload"
          } else {
            ntype = "always"
          }
          let mail = ''
          if (document.getElementById("f_bmail").checked) {
            mail = "f"
          } else if (document.getElementById("t_bmail").checked) {
            mail = "t"
          }
          var info = {
            ininsurlno: this.url.ininsurlno,
            stq: this.url.stq,
            stnamae: namae,
            sttag: tag,
            sturl: url ,
            bmail: mail,
            bmailadmin: document.getElementById("bmailadmin").checked,
            bmailother: document.getElementById("bmailother").checked,
            stemail: adress,
            bnotifytype: ntype,
            inscore: ''
          }
          var option = {}
          if (!window.confirm('この内容で上書きしてもよろしいでしょうか?')) {
            return
          }
          this.$crmapi.callApi(
            {
              method: 'update',
              kind: 'clickurl',
              catalog:'clickurl',
              no: this.url.ininsurlno,
              mode: 'partial',
              info: JSON.stringify(info),
              option:JSON.stringify(option),
              format: 'json'
            }
          ).then((response) => {
            if (response.retval != 0) {
              alert(response.message)
              return
            }
            this.get_clickurls(false, false, true)
            this.disabled_buttons()
          })
        },
        delete_url() {
          if (!window.confirm('選択中のクリック測定URLを削除します。よろしいでしょうか?')) {
            return
          }
          this.$crmapi.callApi(
            {
              method: 'delete',
              kind: 'clickurl',
              no: this.urlno,
              catalog: 'clickurl'
            }
          ).then((response) => {
            if (response.retval != 0) {
              alert(response.message)
              return
            }
            this.get_clickurls(false, false, true)
            this.disabled_buttons()
            this.url = {}
            this.url_org = {}
          })
        },
        check_url() {
          document.getElementById("lcheck").disabled = true
          var check_url = document.getElementById("url").value
          if (!check_url) {
            alert('「設定URL」欄が空欄です。リンクチェックを行うには、「設定URL」欄にURLをご入力ください。')
            return document.getElementById("lcheck").disabled = false
          }
          this.$crmapi.callApi(
            {
              method: 'status',
              kind: 'url',
              url: check_url,
              format: 'json'
            }
          ).then((response) => {
            if (response.result.code != 200) {
              alert('[Fail]URL(' + check_url + ')にコンテンツが存在しない可能性があります。URLが正しいかご確認ください。(HTTPステータスコード404)(このチェックでページの存在を正しく認識できないことがあります。)')
              return  document.getElementById("lcheck").disabled = false
            } else {
              alert('[Ok]URL('+ check_url + ')にコンテンツが存在することが確認できました。')
              return  document.getElementById("lcheck").disabled = false
            }
          }) 
        },
        check_adress() {
          document.getElementById("acheck").disabled = true
          var adress = document.getElementById("addmail").value
          var adresslist = adress.split(',')
          var regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
          var info ={stnamae: "", sturl: ""}
          var recipient = []
          if (!adress) {
            alert('「追加メールアドレス」欄が空欄です。アドレスチェックを行うには、「追加メールアドレス」欄にURLをご入力ください。')
            return document.getElementById("acheck").disabled = false
          }
          for (let ca of adresslist) {
          if (!regex.test(ca)) {
            alert('正しいアドレスではありません。')
            return document.getElementById("acheck").disabled = false
          }
          recipient.push(ca)
          }
           if (!window.confirm('判定を実行されますか?(判定に10秒ほどかかります。10秒後に判定結果が画面に表示されます。)')) {
            return
          }
          this.$crmapi.callApi(
            {
              method: 'status',
              kind: 'clickurlmail',
              info: JSON.stringify(info),
              recipient: JSON.stringify(recipient),
              format: 'json'
            }
          ).then((response) => {
            if (response.retval != 0) {
              alert(response.message)
              return
            } else {
              alert('OK! アドレスの存在が確認できました。')
              return
            }
          }) 
        },
        switch_bmail(type) {
          let check = document.getElementById("bmailadmin")
          if (type == 't_bmail') {
            this.bmail_setting = false
            check.checked = true
            this.bnotifytype = 'always'
          } else {
            this.bmail_setting = true
            check.checked = false
            this.bnotifytype = ''
          }
        },
        abled_adressform(bmailother) {
          if (bmailother.checked) {
            this.add_adress = false
          } else {
            this.add_adress = true
          }
        },
        upload_file() {
          var f = document.getElementById("inputGroupFile04").files
          if (!f[0]) {
            alert("アップロードするファイルを指定してください")
            return
          }
          if (f[0].size > 2097152) {
            alert("ファイルサイズが2MBを超えています")
            return
          }
          var i = {filetag: "uploadfile"}
          var data = new FormData()
          data.append("MAX_FILE_SIZE", 2097152)
          data.append("uploadfile",f[0],)
          data.append("token",store.state.token,)
          data.append("apiname","upload")
          data.append("kind","insurl",)
          data.append("catalog","clickurl",)
          data.append( "info",JSON.stringify(i) )
          var config = {
            headers: {
              "content-type": "multipart/form-data"
            }
          }
          this.axios.post(this.$crmapi.ENDPOINT, data, config) 
          .then((response)=> {
            alert("アップロードは正常終了しました。\n追加:" + response.data.result.add + "件\n更新:" + response.data.result.update + "件" ) 
            this.get_clickurls(false, false, true)
          })
        },
        download_file() {
          let d = new Date()
          let stnamae = ''
          let sttag = ''
          let period = ''
          if (this.after_url_name) {
            stnamae = this.after_url_name;
          }
          if (this.after_tag_name) {
            sttag = this.after_tag_name;
          }
          if (this.after_url_date_for_search) {
            period = this.after_url_date_for_search;
          }
          let filename = 'Insurl_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
          this.$crmapi.download_csv(
            {
              method: 'search',
              kind: 'insurl',
              mode: 'download',
              cond: JSON.stringify({ nameurl: stnamae, sttag: sttag, stperiod: this.format_date(period) }),
              catalog: 'clickurl',
              format: 'csv',
            },
            filename
          )
        },
        download_num(cu) {
          let e = event.currentTarget.getAttribute("name")
          let d = new Date()
          let period = ''
          if (this.after_url_date_for_search) {
            period = this.after_url_date_for_search;
          }
          if (e.match(/inusenum/)) {
            let filename = 'Urlview_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
            this.$crmapi.download_csv(
              {
                method: 'search',
                kind: 'urlview',
                mode: 'download',
                cond: JSON.stringify({mode: "api", ininsurlno: cu, alleigyou: true, stperiod: this.format_date(period), sort: "inmemberno", order: "asc"}),
                catalog: 'urlview',
                format: 'csv',
              },
              filename
            )
          }
          if(e.match(/inpersonnum/)) {
            let filename = 'UrlviewPerson_' + d.getFullYear() + (d.getMonth() + 1) + d.getDate() + '.csv'
            this.$crmapi.download_csv(
              {
                method: 'search',
                kind: 'urlviewperson',
                mode: 'download',
                cond: JSON.stringify({mode: "api", ininsurlno: cu, alleigyou: true, stperiod: this.format_date(period), sort: "inmemberno", order: "asc"}),
                catalog: 'urlview',
                format: 'csv',
              },
              filename
            )
          }
        },
        format_date(ds) {
          if (!ds) {
            return ''
          }
          if (ds[1]) {
            let start_date = ds[0].getFullYear() + '/' + (ds[0].getMonth() + 1) + '/' + ds[0].getDate()
            let end_date = ds[1].getFullYear() + '/' + (ds[1].getMonth() + 1) + '/' + ds[1].getDate()
            return start_date + ' - ' + end_date
          } else {
            let start_date = ds[0].getFullYear() + '/' + (ds[0].getMonth() + 1) + '/' + ds[0].getDate()
            return start_date + ' - '
          }
        },
        on_scroll () {
          let client_height = this.$refs.scroll_area.clientHeight
          let scroll_height = this.$refs.scroll_area.scrollHeight
          let scroll_top = this.$refs.scroll_area.scrollTop
          if (scroll_height - (client_height + scroll_top) < 20) {
        // 読み込み分まで最後までスクロールされた場合
            if (0 < this.numall - this.clickurls.length) {
              if (this.update_url) { return; }
              this.from += 20
              this.update_url = true;
              this.get_clickurls(false, true, false)
            }
          }
        }
      },
      computed: {
        go_url() {
          return (cu) => {                                    
            return cu.sturl
          }
        },
        go_page() {
          return (cu) => {                                    
            return  process.env.VUE_APP_API_ORIGIN + `/crm/2.0/ja/urlview.php?ininsurlno=${cu.ininsurlno}`
          }
        }
      }
    } 
</script>
<style scoped>
div.list {
  border: solid 1px #d5d5d5;
  border-radius: 2px;
  box-shadow: 2px 4px 10px 0px #d5d5d5;
  display: inline-block;
  padding: 10px;
  width: 100%;
  height: 850px;
  margin-bottom: 10px;
  flex: 7;
}
div.form {
  border: solid 1px #d5d5d5;
  border-radius: 2px;
  box-shadow: 2px 4px 10px 0px #d5d5d5;
  display: inline-block;
  padding: 10px;
  width: 100%;
  height: 650px;
  margin-bottom: 10px;
  flex: 3;
}
#infinite_scroll {
  overflow-y: scroll;
  max-height: 620px;
}
table {
  width: 100%;
  border-collapse: collapse;
}
th, .list td {
  text-align: right;
  padding: 3px 0 3px 10px;
  font-weight: normal;
}
table.selectable tbody {
  cursor: pointer;
}
tr.selected {
  background-color: #BBE;
}
#editbtn button {
  margin-right: 5px;
  width: 120px;
  white-space: nowrap;
}
#lcheck,#acheck {
  width: 140px;
  white-space: nowrap;
}
.changed {
  background-color: #ffeef6;
}
.fr {
  float: right;
  margin-left: 5px;
}
.ib {
  display: inline-block;
}
.form-label {
  width: 18%;
  max-width: 120px;
}
input[type="text"] {
  width: 100%;
}
#addmail,#url {
  width: calc(100% - 140px - 4px);
  margin-right: 4px;
}
.subject {
  display: block;
  text-align: left;
}
.mb-3 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem!important;
}
.addresswindow {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  max-width: 440px;
  height: 1.8rem;
  white-space: nowrap;
}
.addresswindow::-webkit-scrollbar {
  height: 6px;
}
.addresswindow::-webkit-scrollbar-track {
  background-color: #ccc;
}
.addresswindow::-webkit-scrollbar-thumb {
  background-color: #17585d;
}
.freverse {
  flex-direction: row-reverse;
}
.hover-red:hover {
  color: red;
}
.hover-blue {
  text-align: center;
  font-weight: 550;
  text-decoration:underline #4169e1;
}
.hover-blue:hover {
  color:  #007bff;
}
.section {
  line-height: 2.6;
  font-size: 1.1em;
  font-weight: 550;
  border-bottom: 2px dotted ;
  border-color: #007bff
}
.section-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.section-item {
  margin-right: 20px;
}
.modal_table {
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 2px;
}
.modal_table th {
  background: #b8daff none repeat scroll 0 0;
  border-bottom: 1px solid #00008b;
  border-top: 1px solid #00008b;
  border-left: 1px solid #CCC;
  line-height: 1.1;
  color: #007bff;
  padding: 2px 14px;
  text-align: center;
}
.modal_table td {
  background: white none repeat scroll 0 0;
  border-bottom: 1px solid #CCC;
  color: #333;
  padding: 2px 4px;
}
#test_help ul li {
  padding-top:5px; 
  padding-bottom:5px;
}
#loading {
  text-align: center;
  color: #ff9800;
  font-weight: bold;
}
.blue {
  color: #007bff;
}
.text-justify {
  text-align: justify;
}
.text-center {
  text-align: center;
}
.nowrap {
  white-space: nowrap;
  font-size: smaller;
}
#url-table tr td:nth-child(2) {
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 0;
}
#url-table tr td:nth-child(4) {
  text-align: start;
  overflow: hidden;
  max-width: 0;
}
.search-box {
  display: flex;
  align-items: flex-end;
}
.search-box input[type="text"] {
  height: 38px;
}
#period {
  width: 250px;
  margin-right: 8px;
  margin-left: 8px;
}
#url-box {
  width: calc((100% - 60px - 90px - 250px - 4px - 8px - 8px - 8px) * 2 / 3);
  margin-right: 8px;
}
#tag-box {
  width: calc((100% - 60px - 90px - 250px - 4px - 8px - 8px - 8px) * 1 / 3);
}
#search_data {
  white-space: nowrap;
  width: 60px;
  margin-right: 4px;
}
#clear_data {
  white-space: nowrap;
  width: 90px;
}
.file-box {
  display: flex;
  justify-content: space-between;
}
.input-group {
  display: flex;
  align-items: flex-end;
}
.radio-box {
  display: flex;
  justify-content: start;
}
.input-group {
  width: 60%
}
.list-margin {
  margin-bottom: 15px;
}
.red {
  color: red;
}
.sidebar {
  width: 120px;
}
.nichiji-box {
  display: flex;
}
.bold {
   font-weight: bold;
 }
</style>

