<template>
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.12.1/css/all.css" crossorigin="anonymous">
  <div id="header" class="clearfix">
    <img src="https://crm.rectus.co.jp/crm/2.0/images/logo_rectus.png">
    <ul class="menu">
      <li class="menu-parent">
        <a href="https://crm.rectus.co.jp/crm/2.0/ja/customer.php">顧客情報</a>
        <label for="menu-parent0">
          <span class="pd"><i class="fas angletoggle"></i></span>
        </label>
        <ul class="menu-child crm-links">
          <li><a href="https://crm.rectus.co.jp/crm/2.0/ja/customer.php" target="_blank">顧客</a></li>
          <li><a href="https://crm.rectus.co.jp/crm/2.0/ja/follow.php" target="_blank">フォロー</a></li>
          <li><a href="https://crm.rectus.co.jp/crm/2.0/ja/enqueteanswer.php" target="_blank">回答</a></li>
          <li><a href="https://crm.rectus.co.jp/crm/2.0/ja/tracking.php" target="_blank">追跡</a></li>
          <li><a href="https://crm.rectus.co.jp/crm/2.0/ja/pageanalysis.php" target="_blank">ページ</a></li>
          <li><a href="https://crm.rectus.co.jp/crm/2.0/ja/search.php" target="_blank">検索/ダウンロード</a></li>
        </ul>
      </li>
      <li class="menu-parent">
        <router-link to="/">メール配信</router-link>
        <label for="menu-parent0">
          <span class="pd"><i class="fas angletoggle"></i></span>
        </label>
        <ul class="menu-child">
          <!--
          <li><router-link to="/" @click="this.pagetitle = 'サマリー'">サマリー</router-link></li>
          -->
          <li><router-link to="/scheduleresult" @click="this.pagetitle = '配信予定・結果'">配信予定・結果</router-link></li>
          <li><router-link to="/edit" @click="this.pagetitle = '原稿設定'">原稿設定</router-link></li>
        </ul>
      </li>
      <li class="menu-parent">
        <router-link to="/clickurl">クリック測定</router-link>
        <label for="menu-parent0">
          <span class="pd"><i class="fas angletoggle"></i></span>
        </label>
       </li>
       <!--
       <li class="menu-parent">
        <router-link to="/enquete">アンケート</router-link>
        <label for="menu-parent0">
          <span class="pd"><i class="fas angletoggle"></i></span>
        </label>
       </li>
       -->
     </ul>
  </div>
  <div class="main">
    <!-- <div>メール配信 &gt; {{ pagetitle }}</div>-->
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      pagetitle: 'サマリー'
    }
  },
  mounted() {
   
  }
}

</script>
