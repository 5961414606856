import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import VueAxios from 'vue-axios'
import crmapi from './crmapi'
import Datepicker from '@vuepic/vue-datepicker'
import RectusPeriodpicker from './components/RectusPeriodpicker'
import '@vuepic/vue-datepicker/dist/main.css'
import './assets/css/common.css'

const axios = Axios.create({
  withCredentials: true, // クロスドメイン間AjaxでCookieを渡す
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
})

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(fas)

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(crmapi)
  .component('Datepicker', Datepicker)
  .component('RectusPeriodpicker', RectusPeriodpicker)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
