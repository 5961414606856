<template>
  <h2>アンケート設定<span class="me-2"/><font-awesome-icon icon="fas fa-question-circle" class="fa-lg blue"/></h2>
  <div class="container-fluid">
    <div class="row freverse">
      <div class="col-xxl mb-2" id="urllist">
        <h3 class="title">登録済みアンケート一覧<span class="me-2"/><font-awesome-icon icon="fas fa-question-circle" class="fa-lg blue"/></h3>
        <div class="list">
          <div class="text-end m-2">
            <span class="action-button hover-red">テンプレートから選択 <font-awesome-icon icon="fa-solid fa-list"/></span>
          </div>
          <div class="search">
            <div class="search-box">
              <div id="url-box">
                <label for="seacrh_url">アンケート名</label>
                <input type="text" class="form-control" id="seacrh_url" placeholder="" v-model="url_name">
              </div>
              <div id="tag-box">
                <label for="seacrh_tag">種別タグ名</label>
                <input type="text" class="form-control" id="seacrh_tag" placeholder="" v-model="tag_name">
              </div>
              <div><span>期間</span>
                <Datepicker id="period" v-model="url_date_for_search" format="yyyy/MM/dd" locale="ja" cancelText="Cancel" selectText="OK" :enable-time-picker="false" range>
                <template #right-sidebar><div class="sidebar text-center">期間選択</div><div class="sidebar hover-red text-center" @click="set_this_month">今月<span class="me-1"/></div><div class="sidebar hover-red text-center" @click="set_last_month">先月<span class="me-1"/></div>
                </template></Datepicker>
              </div>
              <button id="search_data" class="btn btn-outline-primary">検索</button><button id="clear_data" type="button" class="btn btn-outline-info">クリア</button>
            </div>
          </div>
          <div class='list-margin'/>
          <div class="innerlist" id="infinite_scroll" ref="scroll_area">
            <table class="table table-striped table-hover selectable" id="url_list">
              <colgroup>
                <col style="width: 80px;">
                <col style="width: 100px;">
                <col style="width: 400px;">
                <col style="width: 200px;">
              </colgroup>
              <thead class="sticky-top bg-white">
                <tr>
                  <th class='text-center'>No.</th>
                  <th class='text-center'>登録日</th>
                  <th class='text-center'>アンケート名</th>
                  <th class='text-center'>タグ名</th>
                </tr>
              </thead>
              <tbody id="url-table">
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr><td>0017</td><td>2024-01-30</td><td>ご利用のためのアンケート</td><td>ダウンロード</td></tr>
                <tr v-show = " 0 < this.numall - this.clickurls.length">
                  <td colspan="4" id="loading">読み込み中...</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
    export default {
  name: 'EnqueteView'
    }
  </script>
<style scoped>
div.list {
  border: solid 1px #d5d5d5;
  border-radius: 2px;
  box-shadow: 2px 4px 10px 0px #d5d5d5;
  display: inline-block;
  padding: 10px;
  width: 100%;
  height: 850px;
  margin-bottom: 10px;
  flex: 7;
}
div.form {
  border: solid 1px #d5d5d5;
  border-radius: 2px;
  box-shadow: 2px 4px 10px 0px #d5d5d5;
  display: inline-block;
  padding: 10px;
  width: 100%;
  height: 650px;
  margin-bottom: 10px;
  flex: 3;
}
#infinite_scroll {
  overflow-y: scroll;
  max-height: 620px;
}
table {
  width: 100%;
  border-collapse: collapse;
}
th, .list td {
  text-align: right;
  padding: 3px 0 3px 10px;
  font-weight: normal;
}
table.selectable tbody {
  cursor: pointer;
}
tr.selected {
  background-color: #BBE;
}
#editbtn button {
  margin-right: 5px;
  width: 120px;
  white-space: nowrap;
}
#lcheck,#acheck {
  width: 140px;
  white-space: nowrap;
}
.changed {
  background-color: #ffeef6;
}
.fr {
  float: right;
  margin-left: 5px;
}
.ib {
  display: inline-block;
}
.form-label {
  width: 18%;
  max-width: 120px;
}
input[type="text"] {
  width: 100%;
}
#addmail,#url {
  width: calc(100% - 140px - 4px);
  margin-right: 4px;
}
.subject {
  display: block;
  text-align: left;
}
.mb-3 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem!important;
}
.addresswindow {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  max-width: 440px;
  height: 1.8rem;
  white-space: nowrap;
}
.addresswindow::-webkit-scrollbar {
  height: 6px;
}
.addresswindow::-webkit-scrollbar-track {
  background-color: #ccc;
}
.addresswindow::-webkit-scrollbar-thumb {
  background-color: #17585d;
}
.freverse {
  flex-direction: row-reverse;
}
.hover-red:hover {
  color: red;
}
.hover-blue {
  text-align: center;
  font-weight: 550;
  text-decoration:underline #4169e1;
}
.hover-blue:hover {
  color:  #007bff;
}
.section {
  line-height: 2.6;
  font-size: 1.1em;
  font-weight: 550;
  border-bottom: 2px dotted ;
  border-color: #007bff
}
.section-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.section-item {
  margin-right: 20px;
}
.modal_table {
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 2px;
}
.modal_table th {
  background: #b8daff none repeat scroll 0 0;
  border-bottom: 1px solid #00008b;
  border-top: 1px solid #00008b;
  border-left: 1px solid #CCC;
  line-height: 1.1;
  color: #007bff;
  padding: 2px 14px;
  text-align: center;
}
.modal_table td {
  background: white none repeat scroll 0 0;
  border-bottom: 1px solid #CCC;
  color: #333;
  padding: 2px 4px;
}
#test_help ul li {
  padding-top:5px; 
  padding-bottom:5px;
}
#loading {
  text-align: center;
  color: #ff9800;
  font-weight: bold;
}
.blue {
  color: #007bff;
}
.text-justify {
  text-align: justify;
}
.text-center {
  text-align: center;
}
.nowrap {
  white-space: nowrap;
  font-size: smaller;
}
#url-table tr td:nth-child(2) {
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 0;
}
#url-table tr td:nth-child(4) {
  text-align: start;
  overflow: hidden;
  max-width: 0;
}
.search-box {
  display: flex;
  align-items: flex-end;
}
.search-box input[type="text"] {
  height: 38px;
}
#period {
  width: 250px;
  margin-right: 8px;
  margin-left: 8px;
}
#url-box {
  width: calc((100% - 60px - 90px - 250px - 4px - 8px - 8px - 8px) * 2 / 3);
  margin-right: 8px;
}
#tag-box {
  width: calc((100% - 60px - 90px - 250px - 4px - 8px - 8px - 8px) * 1 / 3);
}
#search_data {
  white-space: nowrap;
  width: 60px;
  margin-right: 4px;
}
#clear_data {
  white-space: nowrap;
  width: 90px;
}
.file-box {
  display: flex;
  justify-content: space-between;
}
.input-group {
  display: flex;
  align-items: flex-end;
}
.radio-box {
  display: flex;
  justify-content: start;
}
.input-group {
  width: 60%
}
.list-margin {
  margin-bottom: 15px;
}
.red {
  color: red;
}
.sidebar {
  width: 120px;
}
.nichiji-box {
  display: flex;
}
.bold {
   font-weight: bold;
 }
</style>

